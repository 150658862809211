<template>
  <v-card>
    <v-col>
      <v-row cols="12" class="ma-0 pa-0">
        <CompanyCard
          v-for="(item, index) in dataList"
          :key="index + '_' + item.picked"
          class="ma-2"
          :item="item"
          :actionPicked="actionCard"
        > {{item.picked}} </CompanyCard>
      </v-row>
      <v-row
        class="ma-0 pb-4 pt-4"
        style="display: flex; justify-content: center"
      >
        <v-btn
          elevation="10"
          class="textDecorationBold unsetCapitalize white--text btnTextArea2"
          color="#E52628"
          @click="() => onSelectCompanies()"
        >
          {{ $t("select") }}
        </v-btn>
      </v-row>
    </v-col>
  </v-card>
</template>
<script>
/*
  Name:CompanieModal
  Description: Companie dialog with a grid of companies (CompanyCard components) to select the user.
  API Property
    actionHandler: handler function when button clicked and return an array to parent.
    multi: property to choose one or multiple companies
    
    Components Used:
      CompanyCard
*/
import CompanyCard from "@/components/Companies/CompanyCard.vue";
import Repository from "@/services/Repository";

const CompaniesRepo = Repository.get("companies");
export default {
  name: "CompanyModal",
  components: {
    CompanyCard,
  },
  props: {
    actionHandler: Function, // Handler function when button clicked and return an array to parent.
    multi: { type: Boolean, default: false },
    cmpPicked: { type: Array },
  },
  data() {
    return {
      companiesPicked: [],
      dataList: [],
    };
  },
  async created() {
    await this.fetchCompanies();
    if (this.cmpPicked && this.cmpPicked.length > 0) {
      this.companiesPicked = this.cmpPicked.map((m) => {
        m.picked = true;
        return m;
      });
      this.companiesPicked.map((m)=>{
        let indx = this.dataList.findIndex(x => x['_id'] === m['_id'])
        if (indx != -1){
          this.dataList[indx].picked = true;
        }
      })
    }
  },
  methods: {
    actionCard(inputCompany, value) {
      inputCompany.picked = value;
      if (inputCompany.picked == true) {
        if (!this.multi) {
          if (this.companiesPicked.length > 0) {
            let findIndex = this.dataList.findIndex(
              (d) => d.title == this.companiesPicked[0].title
            );
            this.dataList[findIndex].picked = false;
          }
          this.companiesPicked = [];
        }
        this.companiesPicked.push(inputCompany);
      } else {
        this.companiesPicked = this.companiesPicked.filter((c) => {
          return c.title != inputCompany.title;
        });
      }
    },
    async fetchCompanies() {
      let body = { action: "get_companies" };
      var resp = await CompaniesRepo.get(body);

      this.dataList = resp.data.filter((c) => {
        if (c.IsActive != false) {
          c.picked = false;
          c.title = c.name.split("NewDeal")[1];
          c.subTitle = c.area;
          c.pickImagePath = "companyCard/whiteLogo.png";
          c.unPickImagePath = "companyCard/blueLogo.png";
          return c;
        }
      });
    },
    onSelectCompanies() {
      this.actionHandler(this.companiesPicked);
    },
    async cleanList() {
      await this.dataList.forEach((element) => (element.picked = false));
    },
  },
};
</script>

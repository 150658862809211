<template>
  <v-card :min-height="cardHeight" :min-width="cardWidth" @click.native="pressedHandler()" align="center" elevation="0"
    :hover="true">
    <v-row class="justify-center">
      <v-card class="rounded-circle" :min-width="imageWidth" :min-height="imageHeight" :max-width="imageWidth"
        :max-height="imageHeight" outlined :color="currVal ? colorPicked : ''">
        <v-avatar class="pt-3" :size="avatarSize" :src="currVal ? pickImage : unPickImage">
          <v-img :src="currVal ? pickImage : unPickImage"></v-img>
        </v-avatar>
      </v-card>
    </v-row>
    <v-row class="textContent text-no-wrap justify-center">
      <span :class="boldState" style="font-size: 14px">
        {{ this.title }}
      </span>
    </v-row>
  </v-card>
</template>
<script>
/*
  Name:SelectionIcon
  Description: SelectionIcon is a component that contains an avatar with a title and changes color when we click it.
  API Property
    title:  Title below the v-card with the avatar
    unPickPathImage: unpicked image path
    pickPathImage: picked image path
    imageWidth: picked/unpicked image width
    imageHeight: picked/unpicked image height
    cardWidth: v-card width
    cardHeight: v-card height
    colorPicked: color in Hex when image picked
    avatarSize: avatar size on v-avatar
    pickHandle: fuction for the parent
*/

export default {
  name: "SelectionIcon",
  props: {
    title: { type: String, default: "" }, // Title below the v-card with the avatar
    unPickPathImage: { type: String, default: "selectionInfo/greyAvatar.png" }, // unpicked image path
    pickPathImage: { type: String, default: "selectionInfo/whiteAvatar.png" }, // picked image path
    imageWidth: { type: Number, default: 91 }, // picked/unpicked image width
    imageHeight: { type: Number, default: 91 }, // picked/unpicked image height
    cardWidth: { type: Number, default: 80 }, // v-card width
    cardHeight: { type: Number, default: 123 }, // v-card height
    colorPicked: { type: String, default: "#77BD86" }, // color in Hex when image picked
    avatarSize: { type: Number, default: 71 }, // avatar size on v-avatar
    isSelected: { type: Boolean, default: false }, // is selected
    pickHandler: Function, // Function for the parent
  },
  created() {
    this.initData();
  },
  data() {
    return {
      boldActive: false,
      currVal: false,
      pickImage: "",
      unPickImage: "",
    };
  },
  computed: {
    boldState() {
      return this.boldActive ? "bold--active" : "bold--inactive";
    },
  },
  methods: {
    pressedHandler() {
      this.currVal = !this.currVal;
      this.boldActive = !this.boldActive;
      this.pickHandler(this.currVal, this.title);
    },
    initData() {
      this.pickImage = require("@/assets/" + this.pickPathImage);
      this.unPickImage = require("@/assets/" + this.unPickPathImage);
      this.currVal = this.isSelected;
    },
  },
};
</script>

<style scoped>
.bold--active {
  font-weight: bold;
}

.bold--inactive {
  font-weight: normal;
}
</style>

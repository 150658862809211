<template>
  <v-navigation-drawer
    width="866px"
    style="height: 100%"
    fixed
    right
    v-model="show"
    app
    temporary
  >
    <v-col class="px-12">
      <v-row>
        <v-col align="right" class="pb-0">
          <v-icon @click="onCloseHandler" class="primaryColorOnHover xClass">
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
      <v-row align="center" class="mt-0">
        <v-col align="left" class="pt-0 pb-4">
          <HeaderTitle :title="title" />
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col
          class="flex-grow-0 pt-0"
          @click="openDialog('upload')"
          style="cursor: pointer"
        >
          <v-avatar :size="131">
            <v-img :size="131" :src="imgUrl"> </v-img>
          </v-avatar>
        </v-col>
        <v-col align="left" class="align-self-center">
          <span
            class="primaryColor"
            style="font-family: CenturyGothic; font-size: 20px"
          >
            {{ $t("uploadYourPhoto") + "..." }}
          </span>
          <span
            class="primaryColor"
            style="font-family: CenturyGothic; font-size: 14px"
          >
            <br />{{ $t("photoAtLeast") + " 300px x 300px" }}
          </span>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col align="right" class="pt-0 pb-0">
          <v-btn
            elevation="5"
            align="center"
            :loading="loader"
            @click="onSubmit"
            style="text-transform: unset !important"
            color="error"
          >
            {{ $t("save") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mx-n12" v-if="showInfoModel('accountInfoModel')">
        <v-col class="px-0">
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <AccountInfo
          v-if="showInfoModel('accountInfoModel')"
          :attributes="data.accountInfo"
          :updateAttribute="updateAttribute"
          :validations="this.$v"
          :attributeModel="
            pageModel.accountInfoModel != undefined
              ? pageModel.accountInfoModel
              : []
          "
        />
      </v-row>
      <v-row class="mx-n12" v-if="showInfoModel('basicInfoModel')">
        <v-col class="px-0">
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <BasicInfo
          v-if="showInfoModel('basicInfoModel')"
          :attributes="data.basicInfo"
          :role="initSliderInfo.role.value"
          :updateAttribute="updateAttribute"
          :validations="this.$v"
          :attributeModel="
            pageModel.basicInfoModel != undefined
              ? pageModel.basicInfoModel
              : []
          "
          :regSuffix="initSuffix"
          :intRegSuffix="intRegSuffix"
          :checkSuffixLoader="checkLoader"
          type="create"
        />
      </v-row>
      <v-row class="mx-n12" v-if="showInfoModel('contactInfoModel')">
        <v-col class="pt-12 px-0">
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <ContactInfo
          v-if="showInfoModel('contactInfoModel')"
          :attributes="data.contactInfo"
          :updateAttribute="updateAttribute"
          :validations="this.$v"
          :attributeModel="
            pageModel.contactInfoModel != undefined
              ? pageModel.contactInfoModel
              : []
          "
        />
      </v-row>
      <!-- <v-row class="mx-n12" v-if="showInfoModel('bioInfoModel')">
        <v-col class="px-0">
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <BioInfo
          v-if="showInfoModel('bioInfoModel')"
          :attributes="data.bioInfo"
          :updateAttribute="updateAttribute"
          :attributeModel="
            pageModel.bioInfoModel != undefined ? pageModel.bioInfoModel : []
          "
          :validations="this.$v"
        />
      </v-row> -->
      <v-row class="mx-n12" v-if="showInfoModel('socialInfoModel')">
        <v-col class="px-0">
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <SocialInfo
          v-if="showInfoModel('socialInfoModel')"
          :attributes="data.socialInfo"
          :attributeModel="pageModel.socialInfoModel"
          :updateAttribute="updateAttribute"
          :validations="this.$v"
        />
      </v-row>
      <v-row class="mx-n12" v-if="showInfoModel('addAdminBrokerModel')">
        <v-col class="px-0">
          <v-divider />
        </v-col>
      </v-row>
      <!-- <v-row>
        <addAdminBrokerInfo
          :attributes="data.companyInfo"
          :attributeModel="
            pageModel.addAdminBrokerModel != undefined
              ? pageModel.addAdminBrokerModel
              : []
          "
          :updateAttribute="updateAttribute"
          :validations="this.$v"
        />
      </v-row> -->
      <v-row class="mx-n12" v-if="showInfoModel('addressInfoModel')">
        <v-col class="px-0">
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <AddressInfo
          v-if="showInfoModel('addressInfoModel')"
          :attributes="data.addressInfo"
          :attributeModel="
            pageModel.addressInfoModel != undefined
              ? pageModel.addressInfoModel
              : []
          "
          :updateAttribute="updateAttribute"
          :validations="this.$v"
        />
      </v-row>
      <v-row class="mx-n12" v-if="showInfoModel('personalInfoModel')">
        <v-col class="px-0">
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <PersonalInfo
          v-if="showInfoModel('personalInfoModel')"
          :attributes="data.personalInfo"
          :attributeModel="
            pageModel.personalInfoModel != undefined
              ? pageModel.personalInfoModel
              : []
          "
          :updateAttribute="updateAttribute"
          :validations="this.$v"
        />
      </v-row>
      <v-row class="mx-n12" v-if="showInfoModel('sourceInfoModel')">
        <v-col class="px-0">
          <v-divider />
        </v-col>
      </v-row>
      <!-- Προέλευση επαφής -->
      <v-row v-if="showInfoModel('sourceInfoModel')">
        <v-col align="left">
          <span class="primaryColor textDecorationBold font15">
            {{ $t("contactOrigin") }}
          </span>
        </v-col>
      </v-row>
      <v-row v-if="showInfoModel('sourceInfoModel')">
        <v-col align="left" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <v-select
            v-model="data.Source"
            :items="getRefferalList"
            style="color: #253472"
            item-text="text"
            color="primary"
            clearable
            item-value="val"
            hide-details
            dense
            class="blueBorderedSelect font14 textDecorationBold primaryColor"
            solo
            flat
          >
            <template v-slot:label>
              <span class="font14 textDecorationBold primaryColor">
                {{ $t("chooseOrigin") + "..." }}
              </span>
            </template>
            <template v-slot:selection="{ item }">
              <span
                class="font14 textDecorationBold primaryColor text-truncate"
              >
                {{ $t(item.text) }}
              </span>
            </template>
            <template v-slot:item="{ item }">
              <v-list>
                <v-list-item-title>
                  <v-row>
                    <v-col>
                      <span
                        class="
                          font14
                          textDecorationBold
                          primaryColor
                          text-truncate
                        "
                      >
                        {{ $t(item.text) }}
                      </span>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list>
            </template>
          </v-select>
        </v-col>
        <v-col
          v-if="data.Source == 'Σύσταση'"
          align="left"
          class="pt-0"
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
        >
          <v-autocomplete
            v-model="data.supervisor"
            :label="$t('searchContactName')"
            :items="contactList"
            item-value="_id"
            item-text="name"
            return-object
            :search-input.sync="search"
            :loading="autocompleteLoader"
            hide-no-data
            hide-details
            flat
            clearable
            attach
            hide-selected
            append-icon
            append-outer-icon="mdi-magnify"
          >
            <template v-slot:selection="{ item }">
              <v-col class="flex-grow-0 text-no-wrap pa-0">
                <span>
                  {{ item.name }}
                </span>
              </v-col>
            </template>
            <template slot="item" slot-scope="data">
              <v-list>
                <v-list-item-title>
                  <v-row>
                    <v-col>
                      <span>
                        {{ data.item.name }}
                      </span>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="mx-n12" v-if="showInfoModel('commentInfoModel')">
        <v-col class="px-0">
          <v-divider />
        </v-col>
      </v-row>
      <v-row v-if="showInfoModel('commentInfoModel')">
        <v-col align="left">
          <span class="primaryColor textDecorationBold font15">
            {{ $t("comments") }}
          </span>
        </v-col>
      </v-row>
      <v-row v-if="showInfoModel('commentInfoModel')">
        <v-col>
          <v-textarea
            :label="$t('writeMoreContactInfo') + '...'"
            v-model="data.Comments"
            class="mt-4"
            outlined
            rows="4"
            row-height="15"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row class="mx-n12" v-if="showInfoModel('filesInfoModel')">
        <v-col class="px-0">
          <v-divider />
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col class="pb-6">
          <FilesInfo
            v-if="showInfoModel('filesInfoModel')"
            :documents="documents"
            :docTypesList="docTypesList"
            :role="this.initSliderInfo.role.value"
          />
        </v-col>
      </v-row> -->
      <v-row class="mx-n12" v-if="showInfoModel('createAccountInfoModel')">
        <v-col class="px-0">
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-6">
          <CreateAccountInfo
            v-if="showInfoModel('createAccountInfoModel')"
            :attributes="data.createAccountInfo"
            :attributeModel="
              pageModel.createAccountModel != undefined
                ? pageModel.createAccountModel
                : []
            "
            :updateAttribute="updateAttribute"
            :validations="this.$v"
          />
        </v-col>
      </v-row>
      <v-dialog
        v-model="dialog.open"
        v-if="dialog.type == 'upload'"
        class="pa-6"
      >
        <Upload
          class="pa-6"
          :onlyImgRestrict="true"
          :singularUpload="true"
          :closeHandler="closeDialog"
          :submitHandler="setImage"
        />
      </v-dialog>
    </v-col>
  </v-navigation-drawer>
</template>

<script>
/*
 Name:CreateSlider
 Description: Component to render the main slider to create a contact

 API Property:
    closeHandler: Function to close the slider
    fetchSpecificContact: Function to create/edit the contact
    initSliderInfo: Object for init the slider
    showTags: Boolean to show the tags
    
    Components Used:
      HeaderTitle
      AccountInfo
      BasicInfo
      ContactInfo
      SocialInfo
      AddressInfo
      PersonalInfo
      CreateAccountInfo
      Upload

    Endpoints Functions Called:
      -CompaniesRepo
        get

      -ContactRepo
        createContact
        uploadContactPhoto
        get
*/
import { mapGetters, mapState } from "vuex";
import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
import AccountInfo from "@/components/Contacts/ContactFields/AccountInfo";
import BasicInfo from "@/components/Contacts/ContactFields/BasicInfo";
import ContactInfo from "@/components/Contacts/ContactFields/ContactInfo";
import SocialInfo from "@/components/Contacts/ContactFields/SocialMediaInfo";
import AddressInfo from "@/components/Contacts/ContactFields/AddressInfo.vue";
import PersonalInfo from "@/components/Contacts/ContactFields/PersonalInfo.vue";
// import FilesInfo from "@/components/Contacts/ContactFields/FilesInfo.vue";
// import BioInfo from "@/components/Contacts/ContactFields/BioInfo.vue";
import CreateAccountInfo from "@/components/Contacts/ContactFields/CreateAccount.vue";
// import addAdminBrokerInfo from "@/components/Contacts/ContactFields/AddAdminBroker.vue";
import Upload from "@/components/Upload/Upload.vue";
import Repository from "@/services/Repository";
import CoreHelper from "@/helpers/Core/core";
import i18n from "@/i18n/i18n";
import ContactModel from "@/static/contacts/contacts.js";
const CompaniesRepo = Repository.get("companies");
const ContactRepo = Repository.get("contacts");
import { requiredIf, required, email, sameAs } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  name: "CreateSlider",
  props: {
    closeHandler: Function,
    fetchSpecificContact: Function,
    initSliderInfo: Object,
    showTags: { type: Boolean, default: true },
  },
  components: {
    HeaderTitle,
    AccountInfo,
    BasicInfo,
    ContactInfo,
    SocialInfo,
    AddressInfo,
    PersonalInfo,
    // FilesInfo,
    // BioInfo,
    CreateAccountInfo,
    // addAdminBrokerInfo,
    Upload,
  },
  data() {
    return {
      show: true,
      dialog: {
        open: false,
        type: null,
        data: null,
      },
      image: null,
      search: null,
      checkLoader: false,
      autocompleteLoader: false,
      contactList: [],
      tags: [],
      loader: false,
      data: {
        accountInfo: { ...ContactModel.accountInfoModel },
        basicInfo: { ...ContactModel.basicInfoModel },
        contactInfo: { ...ContactModel.contactInfoModel },
        socialInfo: { ...ContactModel.socialInfoModel },
        addressInfo: { ...ContactModel.addressInfoModel },
        personalInfo: { ...ContactModel.personalInfoModel },
        // bioInfo: { ...ContactModel.bioInfoModel },
        createAccountInfo: { ...ContactModel.createAccountModel },
        addAdminBrokerInfo: { ...ContactModel.addAdminBrokerModel },
        Source: null,
        supervisor: null,
        Comments: null,
      },
      documents: {
        myDocuments: [
          {
            name: "1647076114178medium1648408023.jpg",
            path: "../Uploads/legalDocuments/ec4d8598b18853be46713017a4fc29e2.jpg",
            size: 301678,
            visibility: "public",
            timestamp: 1657788800,
            // "docType": "myDocuments",
            indx: 0,
          },
          {
            name: "274648626_1308204806258589_2890062298352653477_n274648626_1308204806258589_2890062298352653477_n274648626_1308204806258589_2890062298352653477_n.jpg",
            path: "../Uploads/legalDocuments/a3634aa325a5ab6d194614beefdf3e35.jpg",
            size: 511588,
            visibility: "public",
            timestamp: 1657789332,
            // "docType": "myDocuments",
            indx: 1,
          },
          {
            name: "pexels-photo-106399.jpeg",
            path: "../Uploads/legalDocuments/d583a7650490f7c64dccb206a87d5c90.jpeg",
            size: 354238,
            visibility: "public",
            timestamp: 1657797033,
            // "docType": "myDocuments",
            indx: 2,
          },
        ],
        agreements: [
          {
            name: "274648626_1308204806258589_2890062298352653477_n274648626_1308204806258589_2890062298352653477_n274648626_1308204806258589_2890062298352653477_n.jpg",
            path: "../Uploads/legalDocuments/0b050edfab8d8eec3b606f5b06cc3ed5.jpg",
            size: 511588,
            visibility: "public",
            timestamp: 1658734355,
            // "docType": "agreements",
            indx: 0,
          },
          {
            name: "hee hee.pdf",
            path: "../Uploads/legalDocuments/ed684bb779a7fccd4f43c1dbf95c4cf8.pdf",
            size: 1399999,
            visibility: "public",
            timestamp: 1658912290,
            // "docType": "agreements",
            indx: 1,
          },
        ],
      },
      docTypesList: { ...ContactModel.docTypesList },
    };
  },
  validations() {
    const initSliderInfo = this.initSliderInfo;
    return {
      data: {
        accountInfo: {
          UserName: {
            required: requiredIf(() => {
              return [
                "Admin",
                "OfficeAdmin",
                "Secretary",
                "Associate",
              ].includes(initSliderInfo.role.value);
            }),
          },
          UserPassword: {
            required: requiredIf(() => {
              return [
                "Admin",
                "OfficeAdmin",
                "Secretary",
                "Associate",
              ].includes(initSliderInfo.role.value);
            }),
          },
          confirmPassword: {
            required: requiredIf(() => {
              return [
                "Admin",
                "OfficeAdmin",
                "Secretary",
                "Associate",
              ].includes(initSliderInfo.role.value);
            }),
            sameAsPassword: sameAs("UserPassword"),
          },
          // Suffix: {
          //   required: requiredIf((model) => {
          //     return model.isOrganization;
          //   }), },
        },
        basicInfo: {
          FirstName: { required },
          LastName: { required },
          organizationName: {
            required: requiredIf((model) => {
              return model.isOrganization;
            }),
          },
          checkSuffix: {
            required: requiredIf(() => {
              return ["Associate", "Broker", 'OfficeAdmin'].includes(
                initSliderInfo.role.value
              );
            }),
          },
          Suffix: {
            required: requiredIf(() => {
              return ["Associate", "Broker", 'OfficeAdmin'].includes(
                initSliderInfo.role.value
              );
            }),
          },
        },
        // bioInfo: {
        //   specLocations: { required },
        // },
        contactInfo: {
          Email: { required, email },
          Email2: { email },
          Mobile: { required },
        },
      },
    };
  },
  watch: {
    show() {
      if (!this.show) {
        this.closeHandler();
      }
    },
    search(val) {
      val && val != this.data.supervisor && this.fetchContactsList(val);
    },
    "data.basicInfo.Suffix": {
      handler: async function () {
        if (this.data.basicInfo.Suffix.length >= 3)
          return this.fetchContactsSuffix();
      },
    },
  },
  async created() {
    await this.fetchContactsList(null);
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.auth.userDetails,
    }),
    ...mapGetters(["getRefferalList"]),
    title() {
      let role = this.initSliderInfo.role.value;
      if (role == "Admin") {
        return i18n.t("addNewAdmin");
      } else if (role == "Broker" || role == "OfficeAdmin") {
        return i18n.t("addNewBroker");
      } else if (role == "Secretary") {
        return i18n.t("addNewSecretary");
      } else if (role == "Associate") {
         return i18n.t("addNewAgent");
      } else if (role == "owner") {
         return i18n.t("addNewCustomer");
      } else if (role == "Member") {
         return i18n.t("addNewMember");
      } else if (role == "ifAssociate") {
         return i18n.t("addNewIfAssociate");
      } else if (role == "company") {
        return i18n.t("addNewCompany");
      } else if (role == "other") {
        return i18n.t("addNewContact2");
      }
      return "";
    },
    imgUrl() {
      //code for image contact
      if (!this.image) return require("@/assets/contact/upload.png");
      return URL.createObjectURL(this.image);
    },
    pageModel() {
      return ContactModel.createPageModel.filter((m) => {
        if (m.roles == this.initSliderInfo.role.value) {
          return m;
        }
      })[0];
    },
  },
  methods: {
    initSuffix() {
      if (this.data.basicInfo.FirstName && this.data.basicInfo.LastName) {
        this.regSuffix();
      }
    },
    regSuffix() {
      this.data.basicInfo.Suffix = CoreHelper.genSuffix(
        this.data.basicInfo.FirstName,
        this.data.basicInfo.LastName
      );
    },
    intRegSuffix() {
      if (
        this.data.basicInfo.FirstName &&
        this.data.basicInfo.LastName &&
        (this.data.basicInfo.Suffix == null ||
          this.data.basicInfo.Suffix == undefined)
      ) {
        this.regSuffix();
      }
    },
    onCloseHandler() {
      this.closeHandler();
    },
    openDialog(type, data) {
      this.dialog = {
        open: true,
        type,
        data,
      };
    },
    closeDialog() {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
    },
    async onSubmit() {
      var body = {};
      this.$v.$touch();

      if (this.$v.$invalid) return false;
      this.loader = true;
      try {
        //Generic
        // Personal Info
        if (this.data.personalInfo.Birthday != null)
          body["Birthday"] = this.data.personalInfo.Birthday;
        if (this.data.personalInfo.Nameday != null)
          body["Nameday"] = this.data.personalInfo.Nameday;
        if (this.data.personalInfo.AFM != null)
          body["AFM"] = this.data.personalInfo.AFM;
        if (this.data.personalInfo.DOY != null)
          body["DOY"] = this.data.personalInfo.DOY;
        if (this.data.personalInfo.personalId != null)
          body["personalId"] = this.data.personalInfo.personalId;
        if (this.data.personalInfo.otherField != null)
          body["otherField"] = this.data.personalInfo.otherField;
        // Social info
        if (this.data.socialInfo.Website != null)
          body["Website"] = this.data.socialInfo.Website;
        if (this.data.socialInfo.FB != null)
          body["FB"] = this.data.socialInfo.FB;
        if (this.data.socialInfo.Instagram != null)
          body["Instagram"] = this.data.socialInfo.Instagram;
        if (this.data.socialInfo.LinkedIn != null)
          body["LinkedIn"] = this.data.socialInfo.LinkedIn;
        if (this.data.socialInfo.Twitter != null)
          body["Twitter"] = this.data.socialInfo.Twitter;
        if (this.data.socialInfo.otherSocialHandles != null)
          body["otherSocialHandles"] = this.data.socialInfo.otherSocialHandles;
        // Contact Info
        if (this.data.contactInfo.Email != null)
          body["Email"] = this.data.contactInfo.Email;
        if (this.data.contactInfo.Email2 != null)
          body["Email2"] = this.data.contactInfo.Email2;
        if (this.data.contactInfo.Mobile != null)
          body["Mobile"] = this.data.contactInfo.Mobile;
        if (this.data.contactInfo.Telephone != null)
          body["Telephone"] = this.data.contactInfo.Telephone;
        if (this.data.contactInfo.otherContactDetails != null)
          body["otherContactDetails"] =
            this.data.contactInfo.otherContactDetails;
        // Address Info
        if (this.data.addressInfo.Country != null)
          body["Country"] = this.data.addressInfo.Country;
        if (this.data.addressInfo.City != null)
          body["City"] = this.data.addressInfo.City;
        if (this.data.addressInfo.Neighborhood != null)
          body["Neighborhood"] = this.data.addressInfo.Neighborhood;
        if (this.data.addressInfo.Street != null)
          body["Street"] = this.data.addressInfo.Street;
        if (this.data.addressInfo.Zip != null)
          body["Zip"] = this.data.addressInfo.Zip;
        if (this.data.addressInfo.otherAddress != null)
          body["Address"] = this.data.addressInfo.otherAddress[0];
        // if (this.dataaddress) body["GoogleAddress"] = this.address;
        if (this.data.addressInfo.gps) {
          let lat = this.data.addressInfo.gps.split(",")[0]
            ? this.data.addressInfo.gps.split(",")[0]
            : "";
          let lng = this.data.addressInfo.gps.split(",")[1]
            ? this.data.addressInfo.gps.split(",")[1]
            : "";
          lat = parseFloat(parseFloat(lat).toFixed(7));
          lng = parseFloat(parseFloat(lng).toFixed(7));
          body.GPS = [lat, lng];
        }
        // Source Info
        if (this.data.Source != null) {
          body["Source"] = this.data.Source;
          if (this.data.Source == "Σύσταση") {
            body["systasi_userID"] =
              this.data.supervisor && this.data.supervisor._id
                ? this.data.supervisor._id
                : "";
          }
        }
        // Comment Info
        if (this.data.Comments != null) body["Comments"] = this.data.Comments;
        //updated date
        // this.data. UpdatedDay
        body["UpdatedDay"] = moment(new Date()).unix();
        // if contact role is  admin
        if (["Admin"].includes(this.initSliderInfo.role.value)) {
          //account info
          body["Role"] = "Admin";
          body["Type"] = ["Συνεργάτης"];
          body["Company"] = this.initSliderInfo.company.name_id;
          if (this.data.accountInfo.UserName != null) {
            body["UserName"] = this.data.accountInfo.UserName;
          }
          if (this.data.accountInfo.UserPassword != null)
            body["UserPassword"] = this.data.accountInfo.UserPassword;
          // Basic Info
          if (this.data.basicInfo.FirstName != null)
            body["FirstName"] = this.data.basicInfo.FirstName;
          if (this.data.basicInfo.LastName != null)
            body["LastName"] = this.data.basicInfo.LastName;
          if (this.data.basicInfo.FatherName != null)
            body["FatherName"] = this.data.basicInfo.FatherName;
          if (this.data.basicInfo.Suffix != null)
            body["Suffix"] = this.data.basicInfo.Suffix;
          if (this.data.basicInfo.Profession != null)
            body["Profession"] = this.data.basicInfo.Profession;
        } else if (["Broker"].includes(this.initSliderInfo.role.value)) {
          // broker
          body["Role"] = "OfficeAdmin";
          body["Type"] = ["Συνεργάτης"];
          body["Company"] = this.initSliderInfo.company.name_id;

          //Account Info
          if (this.data.accountInfo.userName != null)
            body["UserName"] = this.data.accountInfo.userName;
          if (this.data.accountInfo.UserPassword != null)
            body["UserPassword"] = this.data.accountInfo.UserPassword;
          if (this.data.accountInfo.status != null)
            body["isEditable"] =
              this.data.accountInfo.status == "active" ? true : false;
          if (this.data.accountInfo.Suffix != null)
            body["Suffix"] = this.data.accountInfo.Suffix;

          // Basic Info
          if (this.data.basicInfo.FirstName != null)
            body["FirstName"] = this.data.basicInfo.FirstName;
          if (this.data.basicInfo.LastName != null)
            body["LastName"] = this.data.basicInfo.LastName;
          if (this.data.basicInfo.FatherName != null)
            body["FatherName"] = this.data.basicInfo.FatherName;
          if (this.data.basicInfo.Profession != null)
            body["Profession"] = this.data.basicInfo.Profession;
          if (this.data.basicInfo.Suffix != null)
            body["Suffix"] = this.data.basicInfo.Suffix;

          //Personal Info
          if (this.data.personalInfo.chamberNo != null)
            body["chamberNo"] = this.data.personalInfo.chamberNo;
        } else if (["Secretary"].includes(this.initSliderInfo.role.value)) {
          body["Role"] = this.initSliderInfo.secrType == 1 ? "HR" : "Secretary";
          body["Type"] = ["Συνεργάτης"];
          body["Company"] = this.initSliderInfo.company.name_id;

          //Αccount Info
          if (this.data.accountInfo.UserName != null) {
            body["UserName"] = this.data.accountInfo.UserName;
          }
          if (this.data.accountInfo.UserPassword != null)
            body["UserPassword"] = this.data.accountInfo.UserPassword;
          // Basic Info
          if (this.data.basicInfo.FirstName != null)
            body["FirstName"] = this.data.basicInfo.FirstName;
          if (this.data.basicInfo.LastName != null)
            body["LastName"] = this.data.basicInfo.LastName;
          if (this.data.basicInfo.FatherName != null)
            body["FatherName"] = this.data.basicInfo.FatherName;
          if (this.data.basicInfo.Suffix != null)
            body["Suffix"] = this.data.basicInfo.Suffix;
          if (this.data.basicInfo.Profession != null)
            body["Profession"] = this.data.basicInfo.Profession;
        } else if (["Associate"].includes(this.initSliderInfo.role.value)) {
          body["Role"] =
            this.initSliderInfo.teamDirector == 1 ? "Leader" : "Associate";
          body["Type"] = ["Συνεργάτης"];
          body["Company"] = this.initSliderInfo.company.name_id;
          //Account Info
          if (this.data.accountInfo.userName != null)
            body["UserName"] = this.data.accountInfo.userName;
          if (this.data.accountInfo.UserPassword != null)
            body["UserPassword"] = this.data.accountInfo.UserPassword;
          if (this.data.accountInfo.status != null)
            body["status"] = this.data.accountInfo.status;

          // Bio info
          // if (this.data.bioInfo.bio != null)
          //   body["bio"] = this.data.bioInfo.bio;
          // if (this.data.bioInfo.shortBio != null)
          //   body["short_bio"] = this.data.bioInfo.shortBio;
          // if (this.data.bioInfo.specLocations != null)
          //   body["real_estate_specialization"] =
          //     this.data.bioInfo.specLocations;
          // if (
          //   this.data.bioInfo.qualifications != null &&
          //   this.data.bioInfo.qualifications.length > 0
          // ) {
          //   let ql = [];
          //   this.data.bioInfo.qualifications.map((m) => {
          //     if (
          //       ![null, undefined, ""].includes(m.school) ||
          //       ![null, undefined, ""].includes(m.specialization)
          //     )
          //       ql.push(m);
          //   });
          //   body["qualifications"] = ql;
          // }
          // if (this.data.bioInfo.languages != null && this.data.bioInfo.languages.length > 0){
          //   let lng = [];
          //   lng = this.data.bioInfo.languages.filter((m)=> {return ![null, undefined, ""].includes(m)})
          //   body["languages"] = lng;
          // }

          // Basic Info
          if (this.data.basicInfo.FirstName != null)
            body["FirstName"] = this.data.basicInfo.FirstName;
          if (this.data.basicInfo.LastName != null)
            body["LastName"] = this.data.basicInfo.LastName;
          if (this.data.basicInfo.FatherName != null)
            body["FatherName"] = this.data.basicInfo.FatherName;
          if (this.data.basicInfo.Suffix != null)
            body["Suffix"] = this.data.basicInfo.Suffix;
          if (this.data.basicInfo.Profession != null)
            body["Profession"] = this.data.basicInfo.Profession;

          // Personal info
          if (this.data.personalInfo.chamberNo != null)
            body["chamberNo"] = this.data.personalInfo.chamberNo;
        } else if (["Member"].includes(this.initSliderInfo.role.value)) {
          body["Type"] = ["Member"];
          body["Company"] = this.userDetails.LastCompany;

          if (this.data.basicInfo.isOrganization != null)
            // Basic Info
            body["isOrganization"] = this.data.basicInfo.isOrganization;
          if (this.data.basicInfo.organizationName != null)
            body["organizationName"] = this.data.basicInfo.organizationName;
          if (this.data.basicInfo.organizationDescription != null)
            body["organizationDescription"] =
              this.data.basicInfo.organizationDescription;
          if (this.data.basicInfo.chamberNo != null)
            body["chamberNo"] = this.data.basicInfo.chamberNo;
          if (this.data.basicInfo.FirstName != null)
            body["FirstName"] = this.data.basicInfo.FirstName;
          if (this.data.basicInfo.LastName != null)
            body["LastName"] = this.data.basicInfo.LastName;
          if (this.data.basicInfo.FatherName != null)
            body["FatherName"] = this.data.basicInfo.FatherName;
          if (this.data.basicInfo.Suffix != null)
            body["Suffix"] = this.data.basicInfo.Suffix;
          if (this.data.basicInfo.Profession != null)
            body["Profession"] = this.data.basicInfo.Profession;
          if (this.data.basicInfo.memberTitle != null)
            body["member_title"] = this.data.basicInfo.memberTitle;
        } else if (["ifAssociate"].includes(this.initSliderInfo.role.value)) {
          body["Type"] = ["Υποψήφιος Συνεργάτης"];
          body["Company"] = this.userDetails.LastCompany;
          // Basic Info
          if (this.data.basicInfo.isOrganization != null)
            body["isOrganization"] = this.data.basicInfo.isOrganization;
          if (this.data.basicInfo.organizationName != null)
            body["organizationName"] = this.data.basicInfo.organizationName;
          if (this.data.basicInfo.organizationDescription != null)
            body["organizationDescription"] =
              this.data.basicInfo.organizationDescription;
          if (this.data.basicInfo.chamberNo != null)
            body["chamberNo"] = this.data.basicInfo.chamberNo;
          if (this.data.basicInfo.FirstName != null)
            body["FirstName"] = this.data.basicInfo.FirstName;
          if (this.data.basicInfo.LastName != null)
            body["LastName"] = this.data.basicInfo.LastName;
          if (this.data.basicInfo.FatherName != null)
            body["FatherName"] = this.data.basicInfo.FatherName;
          if (this.data.basicInfo.Suffix != null)
            body["Suffix"] = this.data.basicInfo.Suffix;
          if (this.data.basicInfo.Profession != null)
            body["Profession"] = this.data.basicInfo.Profession;

          // Bio info
          // if (this.data.bioInfo.bio != null)
          //   body["bio"] = this.data.bioInfo.bio;
          // if (this.data.bioInfo.shortBio != null)
          //   body["short_bio"] = this.data.bioInfo.shortBio;
          // if (this.data.bioInfo.specLocations != null)
          //   body["real_estate_specialization"] = this.data.bioInfo.specLocations;
          // if (
          //   this.data.bioInfo.qualifications != null && this.data.bioInfo.qualifications.length > 0
          // ) {
          //   let ql = [];
          //   this.data.bioInfo.qualifications.map((m) => {
          //     if (
          //       ![null, undefined, ""].includes(m.school) ||
          //       ![null, undefined, ""].includes(m.specialization)
          //     )
          //       ql.push(m);
          //   });
          //   body["qualifications"] = ql;
          // }
          // if (this.data.bioInfo.languages != null && this.data.bioInfo.languages.length > 0){
          //   let lng = [];
          //   lng = this.data.bioInfo.languages.filter((m)=> {return ![null, undefined, ""].includes(m)})
          //   body["languages"] = lng;
          // }

          // Create Account
          if (this.data.createAccountInfo.accountCreate) {
            if (this.data.createAccountInfo.UserPassword != null)
              body["UserPassword"] = this.data.createAccountInfo.UserPassword;
            //?? Username
          }
        } else if (["company"].includes(this.initSliderInfo.role.value)) {
          // Basic Info
          if (this.data.basicInfo.organizationName != null)
            body["name"] = this.data.basicInfo.organizationName;
          if (this.data.basicInfo.organizationArea != null)
            body["organizationArea"] = this.data.basicInfo.organizationArea;
          if (this.data.basicInfo.organizationWebsite != null)
            body["organizationWebsite"] =
              this.data.basicInfo.organizationWebsite;

          // Contact Info
          if (this.data.contactInfo.address2 != null)
            body["address2"] = this.data.contactInfo.address2;
          if (this.data.contactInfo.Mobile != null)
            body["tel"] = this.data.contactInfo.Mobile;
          if (this.data.contactInfo.Telephone != null)
            body["tel2"] = this.data.contactInfo.Telephone;
          if (this.data.contactInfo.secretaryEmail != null)
            body["secretaryEmail"] = this.data.contactInfo.secretaryEmail;
          try {
            await CompaniesRepo.get({
              action: "create_company",
              company_data: body,
            });
          } catch (e) {
            throw e;
          }
        } else if (["owner"].includes(this.initSliderInfo.role.value)) {
          // Basic Info
          // body["Type"] = ["?"]; Δεν έχει Role . Μπορεί να έχει 5 διαφορετικά Types
          body["Type"] = [];
          this.initSliderInfo.customerKinds.map((m) => {
            if (m.value == true) body["Type"].push(m.prop);
          });
          body["Company"] = this.userDetails.LastCompany;
          if (this.data.basicInfo.isOrganization != null)
            body["isOrganization"] = this.data.basicInfo.isOrganization;
          if (this.data.basicInfo.organizationName != null)
            body["organizationName"] = this.data.basicInfo.organizationName;
          if (this.data.basicInfo.organizationDescription != null)
            body["organizationDescription"] =
              this.data.basicInfo.organizationDescription;
          if (this.data.basicInfo.chamberNo != null)
            body["chamberNo"] = this.data.basicInfo.chamberNo;
          if (this.data.basicInfo.FirstName != null)
            body["FirstName"] = this.data.basicInfo.FirstName;
          if (this.data.basicInfo.LastName != null)
            body["LastName"] = this.data.basicInfo.LastName;
          if (this.data.basicInfo.FatherName != null)
            body["FatherName"] = this.data.basicInfo.FatherName;
          if (this.data.basicInfo.Suffix != null)
            body["Suffix"] = this.data.basicInfo.Suffix;
          if (this.data.basicInfo.Profession != null)
            body["Profession"] = this.data.basicInfo.Profession;

          // Create Account
          if (this.data.createAccountInfo.accountCreate) {
            if (this.data.createAccountInfo.UserPassword != null)
              body["UserPassword"] = this.data.createAccountInfo.UserPassword;
          }
        } else if (["other"].includes(this.initSliderInfo.role.value)) {
          // Basic Info
          body["Type"] = ["Άλλο"];
          body["Company"] = this.userDetails.LastCompany;
          if (this.data.basicInfo.isOrganization != null)
            body["isOrganization"] = this.data.basicInfo.isOrganization;
          if (this.data.basicInfo.organizationName != null)
            body["organizationName"] = this.data.basicInfo.organizationName;
          if (this.data.basicInfo.organizationDescription != null)
            body["organizationDescription"] =
              this.data.basicInfo.organizationDescription;
          if (this.data.basicInfo.chamberNo != null)
            body["chamberNo"] = this.data.basicInfo.chamberNo;
          if (this.data.basicInfo.FirstName != null)
            body["FirstName"] = this.data.basicInfo.FirstName;
          if (this.data.basicInfo.LastName != null)
            body["LastName"] = this.data.basicInfo.LastName;
          if (this.data.basicInfo.FatherName != null)
            body["FatherName"] = this.data.basicInfo.FatherName;
          if (this.data.basicInfo.Suffix != null)
            body["Suffix"] = this.data.basicInfo.Suffix;
          if (this.data.basicInfo.Profession != null)
            body["Profession"] = this.data.basicInfo.Profession;

          // Create Account
          if (this.data.createAccountInfo.accountCreate) {
            if (this.data.createAccountInfo.UserPassword != null)
              body["UserPassword"] = this.data.createAccountInfo.UserPassword;
          }
        }
        let resp = await ContactRepo.createContact(body);
        if (this.image && resp && resp.data && resp.data._id) {
          let imageFormData = new FormData();
          imageFormData.append("file", this.image);
          imageFormData.append("visibility", true);
          await ContactRepo.uploadContactPhoto(resp.data._id, imageFormData);
        }
        this.loader = false;
        if (
          ["Admin"].includes(this.userDetails.Role) ||
          [undefined, null, ""].includes(body.Role) ||
          (![undefined, null, ""].includes(body.Role) &&
            "Associate" != body.Role)
        ) {
          this.fetchSpecificContact(resp.data._id);
        } else {
          this.closeHandler();
        }
      } catch (e) {
        this.loader = false;
        throw e;
      }
    },
    updateAttribute(kind, attribute, value) {
      this.data[kind][attribute] = value;
    },
    showInfoModel(model) {
      return this.pageModel.infoModels.includes(model);
    },
    async fetchContactsSuffix() {
      this.checkLoader = true;
      this.data.basicInfo.checkSuffix = null;
      try {
        let filters = {
          Suffix: {
            $regex: CoreHelper.toRegex(this.data.basicInfo.Suffix),
            $options: "i",
          },
        };
        let resp = await ContactRepo.get(filters, { page: 0, items: 20 });
        if (resp.data.contacts.length == 0) {
          this.data.basicInfo.checkSuffix = 1;
        }
        this.$v.$touch();

        this.checkLoader = false;
      } catch (e) {
        this.checkLoader = false;
        this.$v.$touch();
        throw e;
      }
    },
    async fetchContactsList(val) {
      this.autocompleteLoader = true;

      try {
        // let word;
        let filters = {
          $or: [{ IsDeleted: { $exists: false } }, { IsDeleted: false }],
        };
        if (
          !["Admin", "OfficeAdmin", "Secretary", "HR"].includes(
            this.userDetails.Role
          )
        ) {
          filters["my_customers"] = true;
        } else if (this.userDetails.Role != "Admin") {
          let cmp = this.userDetails.LastCompany
            ? this.userDetails.LastCompany
            : this.userDetails.CreationCompany
            ? this.userDetails.CreationCompany
            : "";
          filters["LastCompany"] = cmp;
        }
        if (val != null) {
          filters["LastName"] = {
            $regex: CoreHelper.toRegex(val.split(" ")[0]),
            $options: "i",
          };
        }

        let resp = await ContactRepo.get(filters, { page: 0, items: 60 });
        this.contactList = resp.data.contacts.map((d) => {
          return {
            ...d,
            name: (d.LastName + " " + d.FirstName)
              .toUpperCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, ""),
          };
        });
        this.autocompleteLoader = false;
      } catch (e) {
        this.autocompleteLoader = false;
        throw e;
      }
      if (![undefined, null, ""].includes(this.data.supervisor)) {
        this.contactList.push(this.data.supervisor);
      }
    },
    setImage(img) {
      if (img.length > 0) this.image = img[0].file;
      else this.image = null;
    },
  },
};
</script>

<template>
  <v-navigation-drawer
    width="866px"
    style="height: 100%"
    fixed
    right
    v-model="show"
    app
    temporary
  >
    <v-col class="px-12">
      <v-row class="justify-end">
        <v-col class="pb-0 flex-grow-0">
          <v-icon @click="onCloseHandler" class="primaryColorOnHover xClass">
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <HeaderTitle :title="$t('addNewContact')" />
      </v-row>
      <v-row>
        <v-col class="text-capitalize font14" align="left">
          <span> {{$t("chooseCategory") + "*"}} </span>
        </v-col>
      </v-row>
      <v-row class="justify-start mt-6">
        <v-col
          cols="6"
          xl="3"
          lg="3"
          md="3"
          sm="3"
          xs="6"
          v-for="role in filteredAvailableRoles"
          :key="role.title + ' ' + role.selected"
        >
          <SelectionIcon
            :title="role.title"
            colorPicked="#263573"
            :isSelected="role.selected"
            :pickHandler="onCategoryHandler"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="
          selectedRole &&
          selectedRole.value &&
          ['Admin', 'Broker', 'Secretary', 'Associate', 'OfficeAdmin'].includes(
            selectedRole.value
          )
        "
      >
        <v-col>
          <v-row>
            <v-col align="left" class="font14">
              <span> {{ $t("selectNetwork") + " " + selectedRole.label }}</span>
              <span class="errorColor"> {{ selectedCompanyErrors[0] }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" align="left">
              <v-btn
                class="btn-custom unsetCapitalize textDecorationBold"
                key="fBtn0"
                outlined
                color="primary"
                @click="() => openDialog('company', null)"
                >{{ $t("companySelection") }}
                <span v-if="selectedCompany != null">
                  :
                  {{
                    selectedCompany.title + " " + selectedCompany.subTitle
                  }}</span
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-if="
          selectedRole &&
          selectedRole.value &&
          ['Associate'].includes(selectedRole.value)
        "
      >
        <v-col>
          <v-row>
            <v-col align="left" class="font14 pb-0">
              <span>
                {{$t("itsAbout")}}
                <span class="textDecorationBold"> Team Director </span>
                {{"(" + $t("teamManager") + "):"}}
              </span>
              <span class="errorColor"> {{ isTeamDirectorErrors[0] }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <RadioField
                :val="isTeamDirector"
                fieldName="isTeamDirector"
                :isRow="true"
                :options="yesOrNoFields"
                radioValues="yesOrNoFields"
                :radioChangeHandler="updateRadioField"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-if="
          selectedRole &&
          selectedRole.value &&
          ['owner'].includes(selectedRole.value)
        "
      >
        <v-col>
          <v-row>
            <v-col align="left" class="font14 pb-0">
              <span> {{$t("costumerOwner")}} </span>
              <span class="errorColor"> {{ customerKindErrors[0] }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 textDecorationBold font14">
              <v-checkbox
                v-for="(cusKind, i) in customerKinds"
                :key="i"
                v-model="cusKind.value"
                :label="cusKind.label"
                hide-details
                color="warning"
                on-icon="mdi-check-circle"
                off-icon="mdi-checkbox-blank-circle-outline"
                @change="cusKindChanged"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-if="
          selectedRole &&
          selectedRole.value &&
          ['Secretary'].includes(selectedRole.value)
        "
      >
        <v-col>
          <v-row>
            <v-col align="left" class="font14 pb-0">
              <span> {{$t("stallRole") + ":"}} </span>

              <span class="errorColor"> {{ secrTypeErrors[0] }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <RadioField
                :options="secrTypes"
                radioValues="secrTypes"
                :radioChangeHandler="updateRadioField"
                :val="secrType"
                fieldName="secrType"
                :isRow="false"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-footer style="background-color: white" absolute>
        <v-row class="mb-2">
          <v-col align="left">
            <v-btn
              class="btn-custom unsetCapitalize textDecorationBold"
              :class="selectedRole != null ? 'whiteLetters' : ''"
              key="fBtn0"
              :color="selectedRole != null ? 'red' : 'primary'"
              :disabled="selectedRole == null"
              @click="createContact"
            >
              {{$t("createNewContact")}}
            </v-btn>
          </v-col>
        </v-row>
      </v-footer>
    </v-col>
    <v-dialog
      v-model="dialog.open"
      v-if="dialog.type == 'company'"
      class="pa-6"
    >
      <CompaniesModal :actionHandler="onCompanyHandler" :multi="false" />
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
/*
 Name:InitSlider
 Description: Component to render the pop up about the contact create kind

 API Property:
  closeHandler: Function to close the slider
  onCreate: Function to create a contact kind
    
  Components Used:
    HeaderTitle
    SelectionIcon
    RadioField
    CompaniesModal
*/
import HeaderTitle from "@/components/HeaderTitle/HeaderTitle";
import SelectionIcon from "@/components/Fields/SelectionIcon/SelectionIcon.vue";
import CompaniesModal from "@/components/Companies/CompaniesModal.vue";
import RadioField from "@/components/Fields/RadioField/RadioField.vue";
import { mapState } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import i18n from "@/i18n/i18n.js";
export default {
  name: "InitSlider",
  props: {
    closeHandler: Function,
    onCreate: Function,
  },
  components: {
    HeaderTitle,
    SelectionIcon,
    RadioField,
    CompaniesModal,
  },
  data() {
    return {
      show: true,
      selectedRole: null,
      selectedCompany: null,
      isTeamDirector: null,
      secrType: 0,
      availableRoles: [
        {
          title: i18n.t("admin"),
          value: "Admin",
          label: "του διαχειριστή",
          selected: false,
        },
        {
          title: "Broker",
          value: "Broker",
          label: "του broker",
          selected: false,
        },
        {
          title: i18n.t("secretariat") + 'HR',
          value: "Secretary",
          label: "της γραμματείας",
          selected: false,
        },
        {
          title: "Real Estate Agent",
          value: "Associate",
          selected: false,
          label: "του συνεργάτη",
        },
        {
          title: i18n.t("customer"),
          value: "owner",
          selected: false,
        },
        {
          title: "Member",
          value: "Member",
          selected: false,
        },
        {
          title: i18n.t("associateCandidate"),
          value: "ifAssociate",
          selected: false,
        },
        {
          title: i18n.t("otherContact"),
          value: "other",
          selected: false,
        },
      ],
      yesOrNoFields: [
        {
          label: "no",
          value: 0,
        },
        {
          label: "yes",
          value: 1,
        },
      ],
      secrTypes: [
        {
          label: i18n.t("secretariat"),
          value: 0,
        },
        { label: "HR Specialist", value: 1 },
      ],
      customerKindValid: null,
      customerKinds: [
        {
          label: i18n.t("ownerSell"),
          prop: "Ιδιοκτήτης για Πώληση",
          value: false,
        },
        {
          label: i18n.t("ownerRent"),
          prop: "Ιδιοκτήτης για Ενοικίαση",
          value: false,
        },
        {
          label: i18n.t("ownerConsideration"),
          prop: "Ιδιοκτήτης με Αντιπαροχή",
          value: false,
        },
        {
         label: i18n.t("interestedPartyBuy"),
          prop: "Ενδιαφερόμενος για Αγορά",
          value: false,
        },
        {
         label: i18n.t("interestedPartySell"),
          prop: "Ενδιαφερόμενος για Ενοικίαση",
          value: false,
        },
        {
          label: i18n.t("interestedConsideration"),
          prop: "Ενδιαφερόμενος με Αντιπαροχή",
          value: false,
        },
      ],
      dialog: {
        open: false,
        type: null,
        data: null,
      },
    };
  },
  validations: {
    selectedRole: { required },
    selectedCompany: {
      required: requiredIf(function (model) {
        return ["Admin", "Associate", "Broker", "Secretary", "OfficeAdmin"].includes(
          model.selectedRole.value
        );
      }),
    },
    isTeamDirector: {
      required: requiredIf(function (model) {
        return ["Associate"].includes(model.selectedRole.value);
      }),
    },
    customerKindValid: {
      required: requiredIf(function (model) {
        return ["owner"].includes(model.selectedRole.value);
      }),
    },
    secrType: {
      required: requiredIf(function (model) {
        return ["Secretary"].includes(model.selectedRole.value);
      }),
    },
  },
  watch: {
    show() {
      if (!this.show) {
        this.closeHandler();
      }
    },
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.auth.userDetails,
    }),
    selectedRoleErrors() {
      const errors = [];
      if (!this.$v.selectedRole.$dirty) return errors;
      if (!this.$v.selectedRole.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    secrTypeErrors() {
      const errors = [];
      if (!this.$v.secrType.$dirty) return errors;
      if (!this.$v.secrType.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    customerKindErrors() {
      const errors = [];
      if (!this.$v.customerKindValid.$dirty) return errors;
      if (!this.$v.customerKindValid.required)
        errors.push(i18n.t("requiredField"));
      return errors;
    },
    isTeamDirectorErrors() {
      const errors = [];
      if (!this.$v.isTeamDirector.$dirty) return errors;
      if (!this.$v.isTeamDirector.required)
        errors.push(i18n.t("requiredField"));
      return errors;
    },
    selectedCompanyErrors() {
      const errors = [];
      if (!this.$v.selectedCompany.$dirty) return errors;
      if (!this.$v.selectedCompany.required)
        errors.push(i18n.t("requiredField"));
      return errors;
    },
    filteredAvailableRoles() {
      if (this.userDetails.Role == "Admin") return this.availableRoles;
      else if (this.userDetails.Role == "OfficeAdmin")
        return this.availableRoles.filter((f, i) => {
          return i > 1;
        });
      else if (this.userDetails.Role == "Secretary")
        return this.availableRoles.filter((f, i) => {
          return i > 2;
        });
      else if (this.userDetails.Role == "HR")
        return this.availableRoles.filter((f, i) => {
          return i > 3;
        });
      else if (this.userDetails.Role == "Associate")
        return this.availableRoles.filter((f, i) => {
          return i > 3;
        });
      else return [];
    },
  },
  methods: {
    openDialog(type, data) {
      this.dialog = {
        open: true,
        type,
        data,
      };
    },
    closeDialog() {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
    },
    cusKindChanged() {
      let vals = [];
      this.customerKinds.forEach((cus) => vals.push(cus.value));
      this.customerKindValid = vals.includes(true) ? 1 : null;
    },
    onCategoryHandler(isSelected, title) {
      if (this.selectedRole != null) {
        const oldRoleIndex = this.availableRoles.findIndex((object) => {
          return object.value === this.selectedRole.value;
        });
        this.availableRoles[oldRoleIndex].selected = false;
        this.selectedCompany = null;
        this.isTeamDirector = null;
        this.customerKinds.map((m) => {
          m.value = false;
          return m;
        });
      }

      if (isSelected) {
        const newRoleIndex = this.availableRoles.findIndex((object) => {
          return object.title === title;
        });
        this.availableRoles[newRoleIndex].selected = true;
        this.selectedRole = this.availableRoles[newRoleIndex];
      } else {
        this.selectedRole = null;
      }
    },
    onCloseHandler() {
      this.closeHandler();
    },
    onCompanyHandler(company) {
      if (company.length > 0) this.selectedCompany = company[0];
      else this.selectedCompany = null;
      this.closeDialog();
    },
    updateRadioField(data) {
      this[data.fieldName] = this[data.radioValues][data.selectedRadio].value;
    },
    createContact() {
      this.$v.$touch();
      if (this.$v.$invalid) return false;

      var body = {
        role: this.selectedRole,
        company: this.selectedCompany,
        teamDirector: this.isTeamDirector,
        customerKinds: this.customerKinds,
        secrType: this.secrType,
      };

      this.onCreate(body);
    },
  },
};
</script>

<style scoped>
.whiteLetters {
  color: white !important;
}

.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #263573 !important;
  color: white !important;
}
</style>

<template>
  <v-card :elevation="0" class="pa-5">
    <v-col justify="center" class="pr-9">
      <v-row justify="center">
        <v-col class="hTitle pt-0 pb-0">
          <span>{{  $t("newActivity")  }}</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="pt-0 pb-5">
          <span class="hSubTitle ">
            {{  $t("suggestionRegistration")  }}
          </span>
        </v-col>
      </v-row>
      <v-row class="interBold">
        <v-col class="pb-0" align="left">
          <span>
            {{  $t("suggestionDocument")  }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
          <v-text-field v-model="esignCode" :label="$t('esignCode')" clearable :loading="autocompleteLoader"
            hide-details="auto">
          </v-text-field>
        </v-col>
        <v-col class="pt-8" align="left" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
          <v-btn class="btn textDecorationBold " block :class="mobileBreakpoint == true ? 'mt-n9 mb-4' : 'mt-n3'"
            style="height: 40px; " outlined @click="openDialog('upload')">{{  $t("add")  }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
          <v-row>
            <v-col class="pb-0 interBold" align="left">
              <span>
                {{  $t("propertyResponsiblePerson")  }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete v-model="agent" :label="$t('responsiblePartner') + '...*'" :items="agentsList"
                :filter="filterAgents" item-value="_id" item-text="fullname" return-object
                :search-input.sync="searchAgent" :loading="autocompleteLoaderAgent" :error-messages="agentErrors"
                hide-no-data flat clearable hide-selected attach>
                <template v-slot:selection="{ item }">
                  <v-col class="flex-grow-0 text-no-wrap pa-0">
                    <span>
                      {{  item.LastName + " " + item.FirstName  }}
                    </span>
                  </v-col>
                </template>
                <template slot="item" slot-scope="data">
                  <v-list>
                    <v-list-item-title>
                      <v-row>
                        <v-col>
                          <span>
                            {{
                             data.item.LastName + " " + data.item.FirstName 
                            }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
          <v-row>
            <v-col class="pb-0 interBold" align="left">
              <span>
                {{  $t("interested")  }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete v-model="owner" :label="$t('ownerProfile') + '...*'" :items="ownerList" item-text="name"
                item-value="_id" :search-input.sync="search" :error-messages="ownerErrors" :loading="autocompleteLoader"
                hide-no-data clearable flat hide-selected attach/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
          <v-menu ref="dateTimeNote" v-model="dateTimeNote" :close-on-content-click="false"
            transition="scale-transition" min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :label="$t('dateTimeNote') + '...*'" v-model="suggestionDateFormatted"
                prepend-inner-icon="mdi-calendar" @blur="
                  suggestionDate = parseDate(
                    suggestionDateFormatted,
                    'suggestionDate'
                  )
                " :error-messages="suggestionDateErrors">
                <template v-slot:prepend-inner>
                  <v-icon v-bind="attrs" v-on="on"> mdi-calendar </v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker locale="el" v-model="suggestionDate" scrollable @input="dateTimeNote = false" />
          </v-menu>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
          <v-autocomplete v-model="codeEstate" :height="23" :label="$tc('realEstate', 1) + '*'" :items="codeEstateList"
            item-text="EstateCode" item-value="_id" :search-input.sync="searchCodeEstate"
            :error-messages="codeEstateErrors" :loading="codeLoader" hide-no-data clearable flat hide-selected
            attach>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
          <v-select :items="suggestionStatusList" v-model="status" :error-messages="statusErrors" return-object
            :label="$t('suggestionStatus') + '*'" item-text="text" item-value="val">
            <template v-slot:selection="{ item, index }">
              <v-row>
                <v-col>
                  <span v-if="index == 0">
                    {{  $t(item.text)  }}
                  </span>
                  <span v-if="status.length > 1 && index == 0" class="grey--text text-caption">
                    + {{  status.length - 1  }}
                    {{  $t("moreSelected")  }}
                  </span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item="{ item }">
              <v-list>
                <v-list-item-title>
                  <v-row>
                    <v-col style="display: flex; align-items: center">
                      {{  $t(item.text)  }}
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <v-textarea class="borderOutlined" v-model="description" no-resize height="111" outlined
            :label="$t('comment')">
            {{  $t("comment")  }}
          </v-textarea>
        </v-col>
      </v-row>
      <v-row justify="flex-end">
        <v-col align="right">
          <a class="textFontDecoration"
            :style="'color: #E52628; font-size: 14px;  font-weight: bold; margin-right: 30px'" @click="onExitHandler">
            {{  $t("exit")  }}
          </a>
          <v-btn class="btn-custom mb-1 mr-1" @click="sendHandler()" color="error" max-width="129" max-height="38"
            min-width="141" min-height="38">
            {{  $t("send")  }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="dialog.open" v-if="dialog.type == 'upload'" class="pa-6">
      <Upload class="pa-6" :onlyImgRestrict="true" :singularUpload="false" :closeHandler="closeDialog"
        :submitHandler="setImage" />
    </v-dialog>
  </v-card>
</template>
<script>

/* 
   Name:ShowCaseForm
   Description: Is a page with a form to create a new suggestion or edit existing one.
   API Property:
   dataObject: Object with data variables about suggestions. 
    If the object is null means that we will create a suggestion otherwise we edit the prop object.
    Example for the object structure:
     dataObject: {
        esignCode: 1212,
        owner: 146,
        image: {
          lastModified: 1659344700142,
          lastModifiedDate: 213132123,
          name: "Group 2638.png",
          size: 5085,
          type: "image/png",
          webkitRelativePath: "",
        },
        agent: {
          _id: 16,
          "AFM": "114864547",
          "Comments": "[object Object]",
          "Email": "psarris@newdeal.gr",
          "FirstName": "Χρήστος",
          "IsDeleted": false,
          "LastName": "Ψαρρής",
          "Mobile": "6974365326",
          "Photo": "Uploads/Photo/1580305816.jpg",
          "RVID": 174,
          "Role": "Associate",
          "Telephone": ["2103390500"],
          "Type": ["Συνεργάτης"],
          "UserName": "psarris",
          "IsEnabled": true,
          "Suffix": "ΨΑΧ",
          "Company": "NewDeal Κολωνάκι",
          "viberid": {
            "id": "U9VnPdwugQpw17d58/aoGg==",
            "status": "confirmed"
          },
          "CreationCompany": "NewDeal Κολωνάκι",
          "Creator": 22937,
          "LastCompany": "NewDeal Κολωνάκι",
          "LastEditor": 16,
          "AvailableAdsSpitogatos": 1,
          "AvailableAdsXE": 503,
          "AvailableAdsRealtorgr": -1,
          "Birthday": "04/10/76",
          "City": "Αθηνα",
          "Country": "Ελλάδα",
          "DOY": "ΙΘ Αθηνών",
          "Nameday": "25/12",
          "Neighborhood": "Γκύζη",
          "Profession": "Σύμβουλος ακινήτων",
          "Source": "Αγγελία/Internet",
          "Street": "Γούδα",
          "StreetNumber": "4",
          "personalId": "ΑΗ 539951",
          "calendarevents": [],
          "AvailableAdsNewDeal": 500,
          "firstnamenewdealgr": "christos",
          "haspersonalpage": true,
          "lastnamenewdealgr": "psarris",
          "photonewdealgr": [{
            "path": "Uploads/agentphotos/psarris1610816927232.png",
            "size": 140836,
            "dimensions": {
              "x": 824,
              "y": 905,
              "literal": "824 x 905"
            }
          }],
          newdealEmail: "psarris@newdeal.gr",
          FB: "https://el-gr.facebook.com/",
          Instagram: "https://el-gr.facebook.com/",
          reviews: [],
          fullname: "Ψαρρής Χρήστος"
        },
        dateTimeNote: false,
        codeEstate: {
          EndDateAssignation: "-",
          EstateCode: "ΓΓΤ63839",
          LastCompany: "NewDeal Θεσσαλονίκη",
          LastEditor: 107538,
          Location: "Μιαούλη 61, Ιπποκράτειο, Ανατολική Θεσσαλονίκη",
          MainPhoto: "Uploads/EstatePhotos/1661869278853.jpeg",
          MapURL: "https://www.google.gr/maps/dir/40.6116695,22.9607065",
          ObjectivePrice: 170000,
          OutlandCommission: "  ",
          _id: 63839,
        },
        status: {
          id: 0,
          text: "scheduled2",
          val: "Προγραμματισμένη",
        },
        suggestionDate: "2022-08-02",
        description: "Hello!!!"
      },

  Endpoints Functions Called:
    -RealEstateRepo
      get
         
    -ContactRepo
      getOwners
      getAgents
      get

  Components Used:
    Upload
*/
const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

// import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
import CoreHelper from "@/helpers/Core/core";
import ActivitiesStaticLists from "@/static/activities/activities.js";
import Repository from "@/services/Repository";
import i18n from "@/i18n/i18n";
import { required } from "vuelidate/lib/validators";
import Upload from "@/components/Upload/Upload.vue";
const RealEstateRepo = Repository.get("realEstate");
const ContactRepo = Repository.get("contacts");

export default {
  name: "ShowCaseForm",
  components: {
    Upload,
  },
  async created() {
    await this.fetchOwnerList(null);
    await this.fetchAgentsList(null);
    await this.fetchRealEstate(null);
    if (this.dataObject != null) {
      this.esignCode = this.dataObject["esignCode"];
      this.owner = this.dataObject["owner"];
      this.agent = this.dataObject["agent"];
      this.image = this.dataObject["image"];
      this.dateTimeNote = this.dataObject["dateTimeNote"];
      this.codeEstate = this.dataObject["codeEstate"];
      this.suggestionDate = this.dataObject["suggestionDate"];
      this.suggestionDateFormatted = this.dataObject["suggestionDateFormatted"];
      this.status = this.dataObject["status"];
      this.description = this.dataObject["description"];
    }
  },
  data() {
    return {
      esignCode: null,
      owner: null,
      agent: null,
      image: null,
      dateTimeNote: null,
      suggestionDate: null,
      suggestionDateFormatted: null,
      status: null,
      description: null,
      search: null,
      searchAgent: null,
      autocompleteLoaderAgent: false,
      autocompleteLoader: false,
      ownerList: [],
      agentsList: [],
      codeEstateList: [],
      codeEstate: null,
      codeLoader: false,
      searchCodeEstate: null,
      dialog: {
        open: false,
        type: null,
        data: null,
      },
      suggestionStatusList: ActivitiesStaticLists.suggestionStatusList,
    };
  },
  props: {
    dataObject: Object, default() {
      return null;
    },
  },
  watch: {
    suggestionDate() {
      this.suggestionDateFormatted = CoreHelper.formatDate(
        this.suggestionDate
      );
    },

    searchCodeEstate(val) {
      this.fetchRealEstate(val);
    },

    search(val) {
      val && val != this.owner && this.fetchOwnerList(val);
    },
    searchAgent(val) {
      val && val != this.agent && this.fetchAgentsList(val);
    },
  },
  validations() {
    return {
      agent: {
        required,
      },
      owner: {
        required,
      },
    
      codeEstate: {
        required,
      },
      status: {
        required,
      },
      suggestionDate: {
        required,
      }
    };
  },
  computed: {
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    ownerErrors() {
      const errors = [];
      if (!this.$v.owner.$dirty) return errors;
      if (!this.$v.owner.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    agentErrors() {
      const errors = [];
      if (!this.$v.agent.$dirty) return errors;
      if (!this.$v.agent.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    suggestionDateErrors() {
      const errors = [];
      if (!this.$v.suggestionDate.$dirty) return errors;
      if (!this.$v.suggestionDate.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    codeEstateErrors() {
      const errors = [];
      if (!this.$v.codeEstate.$dirty) return errors;
      if (!this.$v.codeEstate.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    statusErrors() {
      const errors = [];
      if (!this.$v.status.$dirty) return errors;
      if (!this.$v.status.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
  },
  methods: {
    onExitHandler() {
    },
    openDialog(type, data) {
      this.dialog = {
        open: true,
        type: type,
        data: data,
      };
    },
    closeDialog() {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
    },
    setImage(img) {
      if (img.length > 0) this.image = img[0].file;
      else this.image = null;
    },
    sendHandler() {
      this.$v.$touch()
      if (this.$v.$invalid) return;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "offerDate") {
          this.offerDate = null;
          this.offerDateFormat = null;
        } else if (dateName == "counterProposalDate") {
          this.counterProposalDateFormat = null;
          this.counterProposalDate = null;
        }
        return null;
      }
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async fetchOwnerList(val, ownerId) {
      this.autocompleteLoader = true;
      let filters;

      if (val != null) {
        let split = val.split(" ");
        let word1;
        let word2;
        if (split.length < 2) {
          word1 = CoreHelper.toRegex(split[0]);
          word2 = word1;
        } else if (split.length == 2) {
          word1 = CoreHelper.toRegex(split[0]);
          word2 = CoreHelper.toRegex(split[1]);
        } else if (split.length > 2) {
          word1 =
            CoreHelper.toRegex(split[0]) + " " + CoreHelper.toRegex(split[1]);
          word2 =
            CoreHelper.toRegex(split[2]) +
            (split[3] ? " " + CoreHelper.toRegex(split[3]) : "");
        }
        filters = [
          { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
          [
            { FirstName: { $regex: word2, $options: "i" } },
            { LastName: { $regex: word1, $options: "i" } },
          ],
        ];
      } else if (ownerId != null) {
        filters = [
          { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
          [{ _id: ownerId }],
        ];
      } else {
        filters = [
          { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
        ];
      }

      try {
        let resp = await ContactRepo.getOwners(filters, { page: 0, items: 20 });

        this.ownerList = resp.data.owners.map((d) => {
          return {
            ...d,
            name: d.name
              .toUpperCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, ""),
          };
        });
        this.autocompleteLoader = false;
      } catch (e) {
        this.autocompleteLoader = false;
        throw e;
      }
    },

    async fetchRealEstate(code) {
      this.codeLoader = true;
      try {
        if (!['', null, undefined].includes(code)) {
          let resp = await RealEstateRepo.get({ $and: { EstateCode: [code] }, $or: {} }, {
            page: 0,
            items: 20,
          });
          this.codeEstateList = resp.data.estates == undefined ? [] : resp.data.estates;
          this.codeLoader = false;
        } else {
          let resp = await RealEstateRepo.get({ $and: {}, $or: {} }, {
            page: 0,
            items: 20,
          });
          this.codeEstateList = resp.data.estates == undefined ? [] : resp.data.estates;
          this.codeLoader = false;
        }

      } catch (e) {
        this.codeEstateList = [];
        this.codeLoader = false;
        throw e;
      }
    },
    async fetchAgentsList(val) {
      let filters;
      if (this.userDetails.Role == "Admin") {
        filters = [
          { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
        ];
      } else if (
        ["Secretary", "OfficeAdmin", "HR"].includes(this.userDetails.Role)
      ) {
        filters = [
          { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
          [{ LastCompany: this.userDetails.Company }],
        ];
      } else if (this.userDetails.Role == "Leader") {
        let ids = [];
        if (this.userDetails.Team && this.userDetails.Team.length > 0)
          ids = [...this.userDetails.Team, this.userDetails._id];
        else ids = [this.userDetails._id];
        filters = {
          _id: { $in: ids },
        };
        if (val != null) {
          filters.LastName = {
            $regex: CoreHelper.toRegex(val.split(" ")[0]),
            $options: "i",
          };
        }
        let resp = await ContactRepo.get(filters, {
          page: 0,
          items: 60,
        });
        this.agentsList = resp.data.contacts.map((a) => {
          return { ...a, fullname: a.LastName + " " + a.FirstName };
        });
        return false;
      } else {
        this.agentsList = [];
        return false;
      }
      try {
        if (val != null) {
          if (filters.length == 1) {
            filters.push([]);
          }
          filters[1].push({
            ["LastName"]: {
              $regex: CoreHelper.toRegex(val.split(" ")[0]),
              $options: "i",
            },
          });
        }
        let resp = await ContactRepo.getAgents(filters, { page: 0, items: 60 });
        this.agentsList = resp.data.agents.map((a) => {
          return { ...a, fullname: a.LastName + " " + a.FirstName };
        });
        this.autocompleteLoaderAgent = false;
      } catch (e) {
        this.autocompleteLoaderAgent = false;
        throw e;
      }
    },
    filterAgents(item, queryText, itemText) {
      if (queryText.length >= 1) {
        let queryWord = queryText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .split(" ");
        let itemWord = itemText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase();
        return queryWord.every((item) => itemWord.includes(item));
      }
    },
  },
};
</script>


<style scoped>
.hTitle {
  color: #263573;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  font-size: 29px !important;
  white-space: pre-wrap !important;
  word-break: break-word !important;
}

.hSubTitle {
  color: #E52628;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  white-space: pre-wrap !important;
  word-break: break-word !important;
}

.interBold {
  font-size: 14px;
  font-family: InterBold;
}

.btn {
  text-transform: unset !important;
  color: #263573;
}

.v-btn-toggle--group>.v-btn.v-btn {
  border-color: #263573 !important;
}

.v-btn-toggle>.v-btn.v-btn {
  border-radius: 5px !important;
  border-width: thin !important;
  background-color: white !important;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: rgba(38, 53, 115, 1) !important;
  text-transform: capitalize !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  background-color: white;
}

#active-button {
  background-color: #263573 !important;
}

.recBtn {
  height: 108px !important;
  width: 118px !important;
}

.recBtnWidth {
  width: 118px !important;
}

.btn-custom {
  text-transform: capitalize !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  font-size: 15px;
  background-color: white;
}

.borderOutlined {
  outline-style: none;
  outline-color: #263573;
  outline-width: medium;

  border-color: #263573 !important;
  border-radius: 5px !important;
  border-width: thin !important;
}

.my-checkbox .v-label {
  font-size: 14px;
}
</style>

<template>
  <v-col>
    <v-row id="topAreaID">
      <v-col>
        <v-row class="align-center justify-space-between topRow">
          <v-col class="text-no-wrap pb-0 pl-1">
            <HeaderTitle
              :title="$tc('contacts', 2)"
              fontSizeTitle="24px"
              fontFamilyTitle="CenturyGothicBold"
              fontSizeSub="16px"
              fontFamilySub="CenturyGothicBold"
            />
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col class="flex-grow-0 px-0" align="left">
            <v-btn-toggle v-model="filterValues.myCustomer">
              <v-btn
                class="btn custom-disabled-btn"
                :value="true"
                style="height: 38px"
                :ripple="false"
                :loading="loading"
              >
                {{ $t("myCustomers") }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col class="flex-grow-0 px-1" align="left">
            <v-btn-toggle v-model="filterValues.faveContacts">
              <v-btn
                :loading="loading"
                value="true"
                class="btn"
                style="height: 38px"
                :ripple="false"
              >
                {{ $t("favoriteContacts") }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col class="flex-grow-0 px-1" align="left">
            <!-- <v-btn-toggle v-model="filterValues.companySelected"> -->
            <v-btn
              class="textDecorationBold btn custom-disabled-btn"
              color="primary"
              outlined
              style="height: 38px"
              :ripple="false"
              @click="() => openDialog('company', null)"
            >
              {{ $t("networkChoice") }}
            </v-btn>
            <!-- </v-btn-toggle> -->
          </v-col>
          <v-col class="flex-grow-0 px-0" align="left">
            <v-select
              hide-details
              v-model="filterValues.rolesSelected"
              multiple
              :items="rolesList"
              item-value="id"
              return-object
              clearable
              dense
              class="blueBorderedSelect font14 textDecorationBold primaryColor"
              style="min-width: 200px"
              solo
              flat
              :label="rolesListLabel"
              :loading="loading"
            >
              <template v-slot:label>
                <span class="font14 textDecorationBold primaryColor">
                  {{ rolesListLabel }}
                </span>
              </template>
              <template v-slot:selection="{ item, index }">
                <span
                  v-if="index == 0"
                  class="font14 textDecorationBold primaryColor text-truncate"
                >
                  {{ item.name }}
                  <span
                    v-if="filterValues.rolesSelected.length > 1 && index == 0"
                    class="grey--text text-caption"
                  >
                    + {{ filterValues.rolesSelected.length - 1 }}
                  </span>
                </span>
              </template>
              <template slot="item" slot-scope="data">
                <v-list>
                  <v-list-item-title>
                    <v-row>
                      <v-col>
                        <span
                          class="font14 textDecorationBold primaryColor text-truncate"
                        >
                          {{ data.item.name }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list>
              </template>
            </v-select>
          </v-col>
          <v-col class="align-end pb-0">
            <v-row class="topFirstRow align-center justify-end">
              <v-col v-if="isAuthAddContact" class="flex-grow-0 pr-2">
                <v-btn
                  style="height: 38px"
                  class="btn-custom"
                  @click="onAddContact"
                  color="error"
                >
                  {{ $t("add") }}
                </v-btn>
              </v-col>
              <v-col class="flex-grow-0 pl-2">
                <!-- <v-btn class="btn-custom" key="fBtn3" outlined color="error" @click="() => openDialog('test', null)">
                  <Icon icon="bx:filter-alt" height="25" outline color="#263573" />
                </v-btn> -->
                <v-btn
                  class="btn-custom"
                  key="fBtn2"
                  outlined
                  color="primary"
                  style="height: 38px"
                  @click="() => openDialog('filters', null)"
                >
                  <Icon
                    icon="bx:filter-alt"
                    height="25"
                    outline
                    color="#263573"
                  />
                </v-btn>
              </v-col>
              <v-col class="flex-grow-0 px-0 pt-2">
                <v-btn-toggle
                  dense
                  v-model="viewChoice"
                  group
                  tile
                  mandatory
                  color="primary"
                >
                  <v-btn
                    :id="viewChoice == 0 ? 'active-button' : ''"
                    class="mr-1"
                    style="height: 38px"
                  >
                    <Icon
                      icon="clarity:grid-view-solid"
                      height="25"
                      :color="viewChoice == 0 ? 'white' : '#263573'"
                    />
                  </v-btn>
                  <v-btn
                    :id="viewChoice == 1 ? 'active-button' : ''"
                    class="mr-3"
                    style="height: 38px"
                    v-if="!mobileBreakpoint"
                  >
                    <Icon
                      icon="ant-design:unordered-list-outlined"
                      height="30"
                      :color="viewChoice == 1 ? 'white' : '#263573'"
                    />
                  </v-btn>
                  <!-- <v-btn
                    :id="viewChoice == 2 ? 'active-button' : 'inactive-button'"
                    class="ml-3 mr-3"
                    style="height: 38px"
                    v-if="false"
                  >
                    <Icon
                      icon="eva:pin-outline"
                      height="25"
                      :color="viewChoice == 2 ? 'white' : '#263573'"
                    />
                  </v-btn> -->
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-space-between">
      <v-col class="HCol pb-0">
        <v-row class="leftHRow">
          <v-col class="pr-0 pt-4 mr-2 flex-grow-0">
            <v-select
              :items="getItemsList"
              dense
              v-model="options.items"
              color="primary"
              item-color="primary"
              @change="optionsRefresh"
              class="textDecorationBold font14"
              style="max-width: 75px"
              height="35"
              hide-details
              outlined
              :loading="loading"
            >
              <template v-slot:selection="{ item }">
                <span
                  class="font14 textDecorationBold primaryColor text-truncate"
                >
                  {{ item }}
                </span> </template
              ><template slot="item" slot-scope="data">
                <v-list>
                  <v-list-item-title>
                    <v-row>
                      <v-col>
                        <span
                          class="font14 textDecorationBold primaryColor text-truncate"
                        >
                          {{ data.item }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list>
              </template>
            </v-select>
          </v-col>
          <v-col
            style="max-width: 320px; min-width: 320px"
            class="flex-grow-0 pt-4 pb-2"
          >
            <v-combobox
              v-model="filterValues.tagsFilt"
              multiple
              solo
              flat
              hide-no-data
              clearable
              outlined
              dense
              :label="$t('tagSearch')"
              prepend-inner-icon="mdi-tag-multiple-outline"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip :key="'tag-' + index" v-if="index == 0">
                  <span v-if="index == 0">
                    {{ $t(item) }}
                  </span>
                  <span
                    v-if="filterValues.tagsFilt.length > 1 && index == 0"
                    class="grey--text text-caption"
                  >
                    + {{ filterValues.tagsFilt.length - 1 }}
                    {{ $t("moreSelected") }}
                  </span>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="flex-grow-0 pb-0 pr-0">
        <v-pagination
          class="main-pgt"
          v-model="options.page"
          :total-visible="6"
          @input="fetchContacts"
          :length="totalPages"
        >
        </v-pagination>
      </v-col>
    </v-row>
    <v-row class="mt-1">
      <v-col align="left" class="d-flex flex-nowrap overflow-auto">
        <FilterTag
          v-for="(tag, index) in tagFilterArray"
          :key="index"
          :display="tag.display"
          :deleteHandler="deleteFilter"
          :attrType="tag.attrType"
          :attrHead="tag.attrHead"
          :attrIndex="tag.attrIndex"
          :supportDelete="tag.noDelete ? false : true"
        />
        <a
          v-if="tagFilterArray && tagFilterArray.length > 0"
          class="text-decoration-underline text-no-wrap"
          color="#43425D"
          @click="clearAllFilter"
        >
          {{ $t("clear") }}</a
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row
          v-if="viewChoice != 2 && (loading || dataFoundStatus != 'true')"
          justify="center"
        >
          <LoaderGif v-if="loading" />
          <NoData
            v-else-if="this.dataFoundStatus == 'false'"
            :showBtn="true"
            :btnTxt="$t('editFilters')"
            :btnClicked="() => openDialog('filters', null)"
          />
          <ServerProblem v-else-if="this.dataFoundStatus == 'error'" />
        </v-row>
        <v-row
          v-else-if="viewChoice == 0"
          :key="'table-' + tableKey"
          class="noScrollBar contactsCards mb-6"
          :style="
            !mobileBreakpoint
              ? 'min-height: 450px !important; max-height:' +
                getReportHeight +
                'px; overflow: auto;'
              : ''
          "
          :class="mobileBreakpoint ? 'justify-center' : 'justify-start'"
        >
          <PersonCard
            v-for="d in data"
            :id="d._id"
            :key="d.EstateCode"
            :role="d.Role"
            :type="d.Type"
            :lastCompany="d.company_name"
            :title="d.title"
            :isFavorite="d.isSaved"
            :subTitle="d.displayRole"
            :bottomTitle="d.company_name ? d.company_name : ''"
            :index="d._id"
            :supportLeftBtn="true"
            :supportRightBtn="true"
            :supportEdit="true"
            :supportDelete="true"
            :supportSelect="true"
            :coloredTextBox="d.company_name ? true : false"
            :photo="d.Photo"
            :isImg="d.isImgAgent"
            :onClickHandler="() => openDialog('viewSlider', d)"
            :onEditHandler="() => openDialog('edit', d)"
            :onDeleteHandler="() => onDelete(d)"
          />
        </v-row>
        <v-row v-else-if="viewChoice == 1" class="mt-n6 mb-6">
          <CustomTable
            :headers="reportHeaderColumns"
            :templates="templates"
            :itemsPerPage="options.items"
            :fixedHeader="true"
            :tableHeight="getTableHeight"
            :handleDelete="onDelete"
            :data="data"
            :supportEdit="true"
            :supportDelete="true"
            :supportView="true"
            :handleView="(d) => openDialog('viewSlider', d)"
            :handleEdit="(d) => openDialog('edit', d)"
            :loading="loading"
            class="row-height-85"
            :key="'table-' + tableKey"
            source="Contacts"
          />
        </v-row>
        <!-- <v-row v-if="viewChoice == 2">
          <CompanyCardMaps
            :data="data"
            :estateOpened="estateOpened"
            :loading="loading"
          />
        </v-row> -->
      </v-col>
      <!-- <v-row> -->
      <!-- <AddAdminBroker/> -->
      <!-- <v-btn @click="() => openDialog('demand', null)">
        </v-btn> -->
      <!-- <v-dialog
        v-model="dialog.open"
        v-if="dialog.open && dialog.type == 'demand'"
        :max-width="690"
      >
        <DemandEvaluation
          :backBtnFunction="closeDialog"
          searching="Καταστήματα Τροφίμων-Ποτών"
          budgetFrom="100.000"
        />
      </v-dialog> -->
      <!-- <TeamInfo 
          :logoImage="logoImage" 
          team="George Ioannidis"
          :ratingData="2.5"
          :numberOfRatings="12"=
          />  -->
      <!-- </v-row> -->
    </v-row>
    <!-- <v-col>
          <ShowCase :icon="logoImage2.path" title="Προετοιμασία Χώρου" subTitle="Υπόδειξη ενδιαφερόμενου"
            status="Ολοκληρώθηκε" date="9/5/2022" :time="time"/>
        </v-col> -->
    <DemandSlider
      v-if="
        dialogArray[dialogArray.length - 1].type == 'demand' &&
        dialogArray[dialogArray.length - 1].open == true
      "
      :data="dialogArray[dialogArray.length - 1].data"
      :closeDemandSlider="closeDialog"
      :fetchContactById="fetchContactById"
      :getSpecificEstate="getSpecificEstate"
      :fetchDemands="
        (newData) =>
          fetchDemands('specific', dialogArray[dialogArray.length - 1].data._id)
      "
      :loading="loading"
    />
    <CreateRealEstateCard
      @createNewContact="openContactModal"
      @close="closeEditDialog"
      :onCreate="onCreateEstate"
      v-if="
        dialogArray[dialogArray.length - 1].type == 'createEstate' &&
        dialogArray[dialogArray.length - 1].open == true
      "
    />
    <RealEstateSlider
      v-if="dialogArray[dialogArray.length - 1].type == 'card'"
      :data="dialogArray[dialogArray.length - 1].data"
      :onEdit="onEdit"
      :openMediaSlider="openDialog"
      :fetchContactById="fetchContactById"
      :uploadSuccessHandler="uploadDocSuccess"
      :updateEstate="updateEstate"
      :refreshEstate="fetchSpecificEstate"
      :onHandleDelete="onDeleteEstate"
      @close="closeDialog"
    />
    <MediaSlider
      v-if="dialogArray[dialogArray.length - 1].type == 'media'"
      :data="dialogArray[dialogArray.length - 1].data"
      :drawer="dialogArray[dialogArray.length - 1].open"
      :close="closeDialog"
      :onEdit="openDialog"
    />
    <EditRealEstateCard
      :onCloseSimple="closeDialog"
      :onClose="closeEditDialog"
      :data="dialogArray[dialogArray.length - 1].data"
      :onView="closeEditDialog"
      :updateEstate="updateEstate"
      :uploadDocSuccessHandler="uploadDocSuccess"
      :updateDocSuccessHandler="updateDocSuccess"
      :deleteDocSuccessHandler="deleteDocSuccess"
      v-if="dialogArray[dialogArray.length - 1].type == 'editEstate'"
    />
    <EditSlider
      v-if="
        dialogArray[dialogArray.length - 1].type == 'edit' &&
        dialogArray[dialogArray.length - 1].open
      "
      :editData="dialogArray[dialogArray.length - 1].data"
      :closeHandler="closeDialog"
      :closeEditDialog="closeEditContact"
      :fetchSpecificContact="fetchSpecificContact"
      :updateUploadedDoc="updateUploadedDoc"
    />
    <ActivitySlider
      v-if="
        dialogArray[dialogArray.length - 1].type == 'test' &&
        dialogArray[dialogArray.length - 1].open
      "
      :closeHandler="closeDialog"
    />
    <Filters
      v-if="
        dialogArray[dialogArray.length - 1].type == 'filters' &&
        dialogArray[dialogArray.length - 1].open
      "
      :onCloseHandler="closeDialog"
      :filterValues="filterValues"
      :onSearch="
        (m) => {
          if (m == true) {
            this.isRefresh = true;
          }
          fetchContacts();
        }
      "
      :updateAttribute="updateFieldValue"
    />
    <InitSlider
      v-if="
        dialogArray[dialogArray.length - 1].type == 'initSlider' &&
        dialogArray[dialogArray.length - 1].open
      "
      :closeHandler="closeDialog"
      :onCreate="onCreate"
    />
    <CreateSlider
      v-if="
        dialogArray[dialogArray.length - 1].type == 'createSlider' &&
        dialogArray[dialogArray.length - 1].open
      "
      :closeHandler="closeDialog"
      :initSliderInfo="dialogArray[this.dialogArray.length - 1].data"
      :showTags="false"
      :fetchSpecificContact="fetchSpecificContact"
    />
    <v-dialog
      v-model="dialogArray[dialogArray.length - 1].open"
      v-if="dialogArray[dialogArray.length - 1].type == 'initKind'"
      class="pa-6"
      max-width="450"
    >
      <InitKind :onSelectHandler="onKindHandler" :closeHandler="closeDialog" />
    </v-dialog>
    <v-dialog v-model="messageDialog.open" width="500">
      <PopupMessage
        :content="messageDialog.content"
        :type="messageDialog.type"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogArray[dialogArray.length - 1].open"
      v-if="dialogArray[dialogArray.length - 1].type == 'company'"
      class="pa-6"
    >
      <CompaniesModal
        :actionHandler="onCompanyHandler"
        :multi="true"
        :cmpPicked="filterValues.companySelected"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogArray[dialogArray.length - 1].open"
      v-if="dialogArray[dialogArray.length - 1].type == 'verifyDelete'"
      width="500"
    >
      <VerifyDeleteContact
        :cancelText="$t('cancel')"
        :onRemove="onVerifyDelete"
        :onCancel="closeDialog"
        :deleteText="$t('imSure')"
        :content="dialogArray[dialogArray.length - 1].content"
        :item="dialogArray[dialogArray.length - 1].data"
        :loading="deleteLoading"
      />
    </v-dialog>
    <v-dialog
      v-model="deleteDialog.open"
      v-if="deleteDialog.type == 'delete'"
      width="500"
    >
      <DeletePopup
        :onRemove="onDeleteEstate"
        :onCancel="closeDeleteDialog"
        cancelText="Ακύρωση"
        deleteText="Ναι, είμαι σίγουρος"
        :content="deleteDialog.content"
        :item="deleteDialog.data"
        :loading="deleteLoading"
      />
    </v-dialog>
    <ContactSlider
      v-if="
        dialogArray[dialogArray.length - 1].type == 'viewSlider' &&
        dialogArray[dialogArray.length - 1].open
      "
      :closeHandler="closeDialog"
      :openEditSlider="openDialog"
      :data="dialogArray[dialogArray.length - 1].data"
      :openRealEstateSlider="openRealEstateSlider"
      :openDemandSlider="openDemandSlider"
      :updateIsSavedHandler="
        (m) => {
          dialogArray[dialogArray.length - 1].data.isSaved = m;
        }
      "
    />

    <v-footer
      inset
      padless
      absolute
      style="background-color: #ebebeb"
      v-if="data.length > 0"
      :class="mobileBreakpoint == true ? 'pb-10' : ''"
    >
      <v-row class="mb-0" style="max-width: 100% !important">
        <v-col align="left" class="main-pgt pt-4 footerCl">
          {{ $t("footerP1") }} {{ options.page ? options.page : 0 }}
          {{ $t("footerP2") }} {{ options.items ? options.items : 0 }}
          {{ $t("footerP3") }} {{ totalResult ? totalResult : 0 }}
          {{ $t("footerP4") }}
        </v-col>
      </v-row>
    </v-footer>
  </v-col>
</template>

<script>
/* 
  Name:Contacts
  Description: Contacts view is the main page for the contacts. There is cards, report, filters and also create, edit and delete choice.
  
  Components Used:
    HeaderTitle
    Icon
    CustomTable
    LoaderGif
    PersonCard
    Filters
    FilterTag
    InitSlider
    InitKind
    CreateSlider
    CreateRealEstateCard
    ContactSlider
    ActivitySlider
    EditSlider
    CompaniesModal
    PopupMessage
    VerifyDeleteContact
    ServerProblem
    NoData
    RealEstateSlider
    DeletePopup
    MediaSlider
    EditRealEstateCard

  Endpoints Functions Called:
  -RealEstateRepo
    getId
    delete

  -CompaniesRepo
    get

  -ContactRepo
    get
    getId
    editContact
*/
import ActivitySlider from "../../views/Activities/ActivitySlider.vue";
import { mapState, mapGetters } from "vuex";
import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
import CustomTable from "@/components/CustomTable/CustomTable.vue";
import PersonCard from "@/components/Persons/PersonCard.vue";
import FilterTag from "@/components/Filters/FilterTag.vue";
import Filters from "@/components/Contacts/Filters.vue";
import { Icon } from "@iconify/vue2";
import ContactsModel from "@/static/contacts/contacts.js";
import LoaderGif from "@/components/Popups/LoaderGif.vue";
import ContactsHelper from "@/helpers/Contacts/contacts.js";
import InitSlider from "@/components/Contacts/CreateContact/InitSlider";
import InitKind from "@/components/Contacts/CreateContact/InitKind";
import CreateSlider from "@/components/Contacts/CreateContact/CreateSlider";
// import AuthHelper from "@/helpers/Core/auth.js";
// import CompanyCardMaps from "@/components/Contacts/CompanyCardMaps.vue";
import ContactSlider from "@/components/Contacts/ContactSlider/ContactSlider";
import CompaniesModal from "@/components/Companies/CompaniesModal.vue";
import EditSlider from "@/components/Contacts/EditContact/EditSlider.vue";
import PopupMessage from "@/components/Popups/PopupMessage.vue";
import VerifyDeleteContact from "@/components/Popups/VerifyDeleteContact.vue";
import Repository from "@/services/Repository";
import i18n from "@/i18n/i18n";
import ServerProblem from "@/components/Popups/ServerProblem.vue";
import NoData from "@/components/Popups/NoData.vue";
import RealEstateSlider from "@/components/RealEstate/RealEstateSlider/RealEstateSlider.vue";
import DeletePopup from "@/components/Popups/DeletePopup.vue";
import MediaSlider from "@/components/RealEstate/MediaSlider";
import RealEstateHelper from "@/helpers/RealEstate/realEstates.js";
import EditRealEstateCard from "@/components/RealEstate/EditRealEstateCard.vue";
import CreateRealEstateCard from "@/components/RealEstate/CreateRealEstateCard.vue";
import DemandSlider from "@/components/Demands/Slider/DemandSlider.vue";
import DemandHelper from "@/helpers/Demand/demand.js";
import CoreHelper from "@/helpers/Core/core";

const RequestsDemandRepo = Repository.get("reqDemand");
const RealEstateRepo = Repository.get("realEstate");
const ContactRepo = Repository.get("contacts");

export default {
  name: "Contacts",
  components: {
    HeaderTitle,
    Icon,
    CustomTable,
    LoaderGif,
    PersonCard,
    Filters,
    FilterTag,
    InitSlider,
    InitKind,
    CreateSlider,
    // CompanyCardMaps,
    CreateRealEstateCard,
    ContactSlider,
    ActivitySlider,
    EditSlider,
    CompaniesModal,
    PopupMessage,
    VerifyDeleteContact,
    ServerProblem,
    NoData,
    RealEstateSlider,
    DeletePopup,
    MediaSlider,
    EditRealEstateCard,
    DemandSlider,
  },
  async created() {
    await this.fetchContacts();
    this.$nextTick(() => {
      this.getHeight();
    });
    // await this.openDialog("verifyDelete", null);
  },
  data() {
    return {
      // items: [
      //   {
      //     icon: require("@/assets/activities/checkList.png"),
      //     title: "Προετοιμασία Χώρου",
      //     subTitle: "Υπόδειξη ενδιαφερόμενου 9/5",
      //     status: "Ολοκληρώθηκε",
      //     date: "9/5/2022",
      //     time: "14:35",
      //     flag: false,
      //   },
      //   {
      //     icon: require("@/assets/activities/indication.png"),
      //     title: "Υπόδειξη",
      //     subTitle: "Σχόλιο Loreum Ipsum",
      //     status: "Εκρεμμεί Ενημέρωση",
      //     date: "9/5/2022",
      //     time: "15:35",
      //     flag: true,
      //   },
      //   {
      //     icon: require("@/assets/activities/date.png"),
      //     title: "Ραντεβού",
      //     subTitle: "Επίσκεψη",
      //     status: "Νέα",
      //     date: "10/5/2022",
      //     time: "08:00",
      //     flag: false,
      //   },
      //   {
      //     icon: require("@/assets/activities/phone.png"),
      //     title: "Follow up με Ανδριανόπουλο",
      //     subTitle: "Σχόλιο Κλήσης",
      //     status: "Νέα",
      //     date: "10/5/2022",
      //     time: "11:00",
      //     flag: false,
      //   },
      //   {
      //     icon: require("@/assets/activities/mail.png"),
      //     title: "Follow up με Ανδριανόπουλο",
      //     subTitle: "Ραντεβού",
      //     status: "Προς info@newdeal.gr",
      //     date: "10/5/2022",
      //     time: "11:00",
      //     flag: false,
      //   },
      //   {
      //     icon: require("@/assets/activities/text.png"),
      //     title: "SMS",
      //     subTitle: "Ραντεβού",
      //     status: "Προς Ευγένιος Ανδριανόπουλος",
      //     date: "10/5/2022",
      //     time: "11:00",
      //     flag: false,
      //   },
      // ],
      // time: "14:35",
      // logoImage2: {
      //   path: require("@/assets/activities/checkList.png"),
      //   absolutePath: true,
      // },
      // logoImage: {
      //   path: require("@/assets/companyCard/blueLogo.png"),
      //   absolutePath: true,
      // },

      // :onRemove="onDeleteComment" :onCancel="closeDialog"
      personalData: null,
      viewChoice: 1,
      isRefresh: false,
      dataFoundStatus: null,
      filterSelection: null,
      disableWatch: false,
      deleteDialog: {
        open: false,
        data: null,
        type: null,
        content: null,
      },
      dialogArray: [
        {
          open: false,
          type: null,
          data: null,
        },
      ],
      allRoles: [
        { id: 2, name: i18n.t("admins"), role: "Admin", type: "Συνεργάτης" },
        { id: 3, name: "Brokers", role: "OfficeAdmin", type: "Συνεργάτης" },
        {
          id: 4,
          name: i18n.t("secretariat"),
          role: "Secretary",
          type: "Συνεργάτης",
        },
        {
          id: 5,
          name: i18n.t("agent"),
          role: "Associate",
          type: "Συνεργάτης",
        },
        {
          id: 6,
          name: "Team Directors",
          role: "Leader",
          type: "Συνεργάτης",
        },
        {
          id: 7,
          name: i18n.t("ownerSell"),
          role: null,
          type: "Ιδιοκτήτης για Πώληση",
        },
        {
          id: 8,
          name: i18n.t("ownerRent"),
          role: null,
          type: "Ιδιοκτήτης για Ενοικίαση",
        },
        {
          id: 9,
          name: i18n.t("ownerConsideration"),
          role: null,
          type: "Ιδιοκτήτης με Αντιπαροχή",
        },
        {
          id: 10,
          name: i18n.t("interestedPartyBuy"),
          role: null,
          type: "Ενδιαφερόμενος για Αγορά",
        },
        {
          id: 11,
          name: i18n.t("interestedPartySell"),
          role: null,
          type: "Ενδιαφερόμενος για Ενοικίαση",
        },
        {
          id: 12,
          name: i18n.t("interestedConsideration"),
          role: null,
          type: "Ενδιαφερόμενος με Αντιπαροχή",
        },
        {
          id: 13,
          name: i18n.t("candidatePartners"),
          role: null,
          type: "Υποψήφιος Συνεργάτης",
        },
        { id: 14, name: "HR Specialists", role: "HR", type: "Συνεργάτης" },
        { id: 15, name: "Members", role: null, type: "Member" },
      ],
      custRoles: [
        {
          id: 1,
          name: "Ιδιοκτήτης (Πώληση)",
          role: null,
          type: "Ιδιοκτήτης για Πώληση",
        },
        {
          id: 2,
          name: "Ιδιοκτήτης (ενοικίαση)",
          role: null,
          type: "Ιδιοκτήτης για Ενοικίαση",
        },
        {
          id: 3,
          name: "Ιδιοκτήτης (αντιπαροχή)",
          role: null,
          type: "Ιδιοκτήτης με Αντιπαροχή",
        },
        {
          id: 4,
          name: "Ενδιαφερόμενος (Αγορά)",
          role: null,
          type: "Ενδιαφερόμενος για Αγορά",
        },
        {
          id: 5,
          name: "Ενδιαφερόμενος (ενοικίαση)",
          role: null,
          type: "Ενδιαφερόμενος για Ενοικίαση",
        },
        {
          id: 6,
          name: "Ενδιαφερόμενος (αντιπαροχή)",
          role: null,
          type: "Ενδιαφερόμενος με Αντιπαροχή",
        },
        { id: 7, name: "Members", role: null, type: "Member" },
      ],

      data: [],
      estatesData: [],
      availableEstates: [],
      soldEstates: [],
      tableKey: 1,
      loading: false,
      headers: ContactsModel.headers,
      templates: ContactsModel.templates,
      filterValues: ContactsModel.filterValues,
      filterObject: [],
      kindInfo: null,
      deleteObject: null,
      totalPages: 0,
      totalResult: 0,
      totalEstatesPages: 0,
      totalEstatesResult: 0,
      options: {
        page: 1,
        items: 20,
      },
      estateOptions: {
        page: 1,
        items: 3,
      },
      messageDialog: {
        type: null,
        open: false,
        content: null,
      },
      dialog: {
        open: false,
        type: null,
        data: null,
      },
      estateOpened: null,
      height: null,
      windowHeight: null,
      windowWidth: null,
      deleteLoading: false,
    };
  },
  computed: {
    ...mapGetters(["getItemsList"]),
    ...mapState({
      realEstateFilter: (state) => state.realEstate,
      userDetails: (state) => state.auth.userDetails,
    }),
    rolesList() {
      return this.filterValues.myCustomer ? this.custRoles : this.allRoles;
    },
    sort() {
      let lSort = this.headers.filter((f) => {
        return f.sort;
      });
      return lSort.length == 0 ? [{}] : lSort;
    },
    rolesListLabel() {
      return this.filterValues.myCustomer
        ? i18n.t("allMyClients")
        : i18n.t("allTheRoles");
    },
    tagFilterArray() {
      return this.filterObject[0];
    },
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    getReportHeight() {
      return this.windowHeight - this.height - 220;
    },
    getTableHeight() {
      return this.windowHeight - this.height - 130 > 275 &&
        this.windowWidth > 600
        ? this.windowHeight - this.height - 240
        : this.windowWidth > 600
        ? 275
        : 400;
    },

    reportHeaderColumns() {
      return this.headers.map((m) => {
        m.text = i18n.t(m.text);
        return m;
      });
    },
    isAuthAddContact() {
      return (
        this.userDetails.Type &&
        this.userDetails.Type.type.includes("Συνεργάτης")
      );
    },
  },
  mounted() {
    window.addEventListener("resize", this.getHeight);
  },
  unmounted() {
    window.removeEventListener("resize", this.getHeight);
  },
  watch: {
    viewChoice() {
      this.$nextTick(() => {
        this.getHeight();
      });
    },
    mobileBreakpoint() {
      if (this.mobileBreakpoint == true && this.viewChoice == 1)
        this.viewChoice = 0;
    },
    "filterValues.faveContacts"() {
      if (!this.disableWatch) {
        this.isRefresh = true;
        this.fetchContacts();
      }
    },
    "filterValues.tagsFilt"() {
      if (!this.disableWatch) {
        this.isRefresh = true;
        this.fetchContacts();
      }
    },
    "filterValues.companySelected"() {
      if (!this.disableWatch) {
        this.isRefresh = true;
        this.fetchContacts();
      }
    },
    "filterValues.myCustomer"() {
      if (!this.disableWatch) {
        if (
          this.filterValues.rolesSelected &&
          this.filterValues.rolesSelected.length > 0
        ) {
          this.filterValues.rolesSelected = [];
        } else {
          this.isRefresh = true;
          this.fetchContacts();
        }
      }
    },
    "filterValues.rolesSelected"() {
      if (!this.disableWatch) {
        this.isRefresh = true;
        this.fetchContacts();
      }
    },
  },
  methods: {
    async getSpecificEstate(id) {
      let resp = await RealEstateRepo.getId(id);
      if (resp && resp.data && resp.data.estates) {
        this.openRealEstateSlider(
          resp.data.estates[0] ? resp.data.estates[0] : null
        );
      }
    },
    async fetchDemands(type, id) {
      try {
        let object = {};
        let array = [];
        let resp = [];

        this.loading = true;
        let params;
        if (type != "specific") {
          this.filterObject = await DemandHelper.setFilterObject(
            this.filterValues
          );

          object = this.filterObject[1];
          if (Object.keys(this.sort[0]).length === 0) {
            params = {
              page: this.options.page - 1,
              items: this.options.items,
            };
          } else {
            let lSort = await CoreHelper.customStringify(
              this.sort[0].value,
              this.sort[0].sort == "asc" ? 1 : -1 /* 1 Asc, -1 Desc */
            );
            params = {
              page: this.options.page - 1,
              items: this.options.items,
              sort: lSort,
            };
          }
          resp = await RequestsDemandRepo.get(object, params);
        } else {
          params = {
            page: 0,
            items: 1,
          };
          object = { $and: [{ _id: id }] };
          resp = await RequestsDemandRepo.get(object, params);
        }
        if (resp.data != undefined && resp.data.requests != undefined) {
          if (resp.data.requests.length > 0) {
            this.dataFoundStatus = "true";
            let initData = DemandHelper.initData(resp.data, this.currency);

            array = initData[0];
            this.unReadCNT = initData[1];
            if (type == "specific") {
              // Change the data to child
              this.dialogArray[this.dialogArray.length - 1].data = array[0];
              // Change the data to parent for the specific demand
              let index = this.data.findIndex((d) => {
                return d._id === array[0]._id;
              });
              this.data[index] = array[0];
            } else {
              this.data = array;
              this.totalResult = resp.data.results;
              this.totalPages = Math.ceil(
                this.totalResult / this.options.items
              );
              if (this.totalPages == 0) this.totalPages = 1;
            }
          } else {
            if (type != "specific") {
              this.dataFoundStatus = "false";
              this.data = [];
              this.totalResult = 0;
              this.totalPages = 0;
            }
          }
        }
        this.loading = false;
      } catch (e) {
        this.dataFoundStatus = "false";
        this.loading = false;
        throw e;
      }
    },
    async fetchContacts() {
      try {
        let object = {};
        this.loading = true;
        this.filterObject = await ContactsHelper.setFilterObject(
          this.filterValues
        );
        object = this.filterObject[1];
        let params;
        if (this.isRefresh == true) {
          this.options.page = 1;
          this.isRefresh = false;
        }
        params = {
          page: this.options.page - 1,
          items: this.options.items,
        };
        var resp = await ContactRepo.get(object, params);
        if (resp.data && resp.data.contacts && resp.data.contacts.length > 0) {
          this.dataFoundStatus = "true";
          this.data = resp.data.contacts.map((c) => {
            return ContactsHelper.initDataContact(c);
          });
          this.totalResult = resp.data.results;
          this.totalPages = Math.ceil(this.totalResult / this.options.items);
          if (this.totalPages == 0) this.totalPages = 1;
        } else {
          this.dataFoundStatus = "false";
          this.data = [];
          this.totalResult = 0;
          this.totalPages = 0;
        }
        this.closeDialog();
        this.loading = false;
      } catch (e) {
        this.dataFoundStatus = "error";
        this.loading = false;
        throw e;
      }
    },
    characteristicsInfo(object) {
      return RealEstateHelper.getCharacteristicsEstates(object);
    },
    openDemandSlider(data) {
      this.openDialog("demand", data);
    },
    onAddContact() {
      if (this.userDetails.Role == "Admin") {
        this.openDialog("initKind", null);
      } else {
        this.openDialog("initSlider", null);
      }
    },
    async onVerifyDelete(item) {
      let body = { IsDeleted: true };
      try {
        this.deleteLoading = true;
        await ContactRepo.editContact(item._id, body);
        this.data = this.data.filter((f) => {
          return f._id != item._id;
        });
        this.deleteLoading = false;
      } catch (e) {
        this.deleteLoading = false;
        throw e;
      }
      await this.openMessageDialog("success", i18n.t("deletedContact"));
      await setTimeout(() => {
        this.closeMessageDialog();
      }, 2400);
    },
    openRealEstateSlider(data) {
      this.estateOpened = data["_id"] ? data["_id"] : null;
      this.openDialog("card", data);
    },
    async onDelete(item) {
      await this.openDialog("verifyDelete", item);
    },
    openMessageDialog(type, content) {
      this.messageDialog = {
        open: true,
        type: type,
        content: content,
      };
    },
    closeMessageDialog() {
      this.messageDialog = {
        open: false,
        type: null,
        content: null,
      };
    },
    async fetchContactById(id) {
      let object = { _id: id };
      let params;
      params = {
        page: 0,
        items: 1,
      };
      var resp = await ContactRepo.get(object, params);
      if (resp.data != undefined && resp.data.contacts != undefined) {
        if (resp.data.contacts.length > 0) {
          this.personalData = resp.data.contacts.map((c) => {
            return ContactsHelper.initDataContact(c);
          });
        }
      }
      this.openDialog("viewSlider", this.personalData[0]);
      //this.dialog.type = "profileSlider";
    },
    onEdit(item) {
      this.estateOpened = item["_id"] ? item["_id"] : null;
      this.openDialog("editEstate", item);
    },
    onKindHandler(kind) {
      this.kindInfo = kind;
      if (kind.value == "company") {
        this.openDialog("createSlider", { role: { value: "company" } });
      } else {
        this.openDialog("initSlider", null);
      }
    },
    onCreate(basicPerson) {
      this.openDialog("createSlider", basicPerson);
    },
    updateFieldValue(key, value, index) {
      if (index == undefined) this.filterValues[key] = value;
      else {
        this.filterValues[key] = this.filterValues[key].filter((m, i) => {
          return i != index;
        });
      }
    },
    openEditContact(data) {
      this.openDialog("edit", data);
    },
    async deleteFilter(attrHead, attrType, attrValue, attrIndex) {
      if (attrType == "string") {
        for (let i = 0; i < attrHead.length; i++) {
          this.updateFieldValue(attrHead[i].name, null);
        }
      }
      if (attrType == "array") {
        for (let i = 0; i < attrHead.length; i++) {
          this.updateFieldValue(attrHead[i].name, null, attrIndex);
        }
      }
      await this.fetchContacts();
    },
    async onDeleteEstate(item) {
      try {
        this.deleteLoading = true;
        await RealEstateRepo.delete(item["_id"]);
        this.closeEditDialog();
        this.deleteLoading = false;
      } catch (e) {
        this.deleteLoading = false;
        throw e;
      }
      this.closeDeleteDialog();
      this.openMessageDialog(
        "success",
        `Μόλις διαγράψατε το ακίνητο με κωδικό ${item.EstateCode}`
      );
      setTimeout(() => {
        this.closeMessageDialog();
      }, 2400);
    },
    async clearAllFilter() {
      this.disableWatch = true;
      for (const key in this.filterValues) {
        this.updateFieldValue(key, null);
      }
      await this.fetchContacts();
      this.disableWatch = false;
    },
    // filtersBtn(type) {
    //   this.filterSelection = type;
    //   this.openDialog("filter", null);
    // },
    async optionsRefresh() {
      this.options.page = 1;
      await this.fetchContacts();
    },
    // isAuthorized(action) {
    //   return AuthHelper.authorization(
    //     action,
    //     this.userDetails["_id"],
    //     this.userDetails["_id"]
    //   );
    // },

    closeEditContact() {
      this.fetchSpecificContact(
        this.dialogArray[this.dialogArray.length - 1].data._id,
        true
      );
      //this.closeDialog();
    },
    async onCreateEstate(id) {
      if (id) {
        await this.closeEditDialog();
        await this.fetchSpecificEstate(id, "add");
      }
    },
    closeDialog() {
      if (this.dialogArray.length > 1) {
        //let popped = this.dialogArray[this.dialogArray.length - 1];
        this.dialogArray = this.dialogArray.filter(
          (obj, index) => index < this.dialogArray.length - 1
        );
      }
    },

    closeEditDialog() {
      if (
        this.dialogArray[this.dialogArray.length - 1].type == "editEstate" &&
        this.dialogArray[this.dialogArray.length - 2].type == "card"
      ) {
        this.dialogArray = this.dialogArray.filter(
          (obj, index) => index < this.dialogArray.length - 2
        );
      } else {
        if (this.dialogArray.length > 1) {
          //let popped = this.dialogArray[this.dialogArray.length - 1];
          this.dialogArray = this.dialogArray.filter(
            (obj, index) => index < this.dialogArray.length - 1
          );
        }
      }
    },
    openDialog(type, data) {
      let obj = {};
      obj = { type: type, data: data, open: true };
      if (["initKind", "initSlider", "createSlider"].includes(type)) {
        if (this.dialogArray.length == 1) {
          this.dialogArray.push(obj);
        } else {
          this.dialogArray.pop();
          this.dialogArray.push(obj);
        }
      } else {
        this.dialogArray.push(obj);
      }
    },
    updateDocSuccess(estateId, docTypeProp, objIndx, objVal) {
      const index = this.estatesData.findIndex((d) => {
        return d._id === estateId;
      });
      if (
        index != -1 &&
        this.estatesData[index]["documents"][docTypeProp] != undefined
      )
        this.estatesData[index]["documents"][docTypeProp][objIndx].visibility =
          objVal.visibility;
    },
    deleteDocSuccess(estateId, docTypeProp, objIndx) {
      const index = this.data.findIndex((d) => {
        return d._id === estateId;
      });
      this.estatesData[index]["documents"][docTypeProp].splice(objIndx, 1);
      this.dialogArray[this.dialogArray.length - 1].data = {
        ...this.dialogArray[this.dialogArray.length - 1].data,
        documents: this.estatesData[index]["documents"],
      };
    },
    async fetchSpecificContact(id, openEdit) {
      let params;
      params = {
        page: 0,
        items: this.options.items,
      };
      let resp = await ContactRepo.getId(id, params);
      if (
        resp &&
        resp.data &&
        resp.data.contacts &&
        resp.data.contacts.length
      ) {
        const index = this.data.findIndex((d) => {
          return d._id === resp.data.contacts[0]?._id;
        });
        if (index != -1) {
          this.data[index] = resp.data.contacts[0];

          this.data[index] = ContactsHelper.initDataContact(this.data[index]);
          if (openEdit == true) {
            if (
              this.dialogArray[this.dialogArray.length - 2].type == "viewSlider"
            ) {
              this.dialogArray[this.dialogArray.length - 2].data = {
                ...this.data[index],
              };
            }
            this.closeDialog();
          }
        } else {
          if (openEdit == true) {
            if (
              this.dialogArray[this.dialogArray.length - 2].type == "viewSlider"
            ) {
              this.dialogArray[this.dialogArray.length - 2].data = {
                ...resp.data.contacts[0],
              };
            }
            this.closeDialog();
          }
        }
        if (openEdit != true) {
          this.openDialog("viewSlider", resp.data.contacts[0]);
        }
        this.tableKey++;
      } else {
        this.closeDialog();
      }
    },
    openDeleteDialog(type, data, content) {
      this.deleteDialog = {
        open: true,
        type: type,
        data: data,
        content: content,
      };
    },
    closeDeleteDialog() {
      this.deleteDialog = {
        open: false,
        type: null,
        data: null,
        content: null,
      };
    },

    async fetchSpecificEstate(id, action) {
      let resp = await RealEstateRepo.getId(id);
      if (resp && resp.data && resp.data.estates) {
        if (action == "add") this.onEdit(resp.data.estates[0]);
        const index = this.estatesData.findIndex((d) => {
          return d._id === resp.data.estates[0]?._id;
        });
        if (index != -1) {
          this.estatesData[index] = resp.data.estates[0];
          this.dialogArray[this.dialogArray.length - 1].data =
            this.estatesData[index];
        } else {
          this.estatesData.unshift(resp.data.estates[0]);
          this.dataFoundStatus = "true";
        }
      }
    },

    updateEstate(estateId, objProp, objVal) {
      const index = this.estatesData.findIndex((d) => {
        return d._id === estateId;
      });
      if (index != -1) {
        this.estatesData[index][objProp] = objVal;
        try {
          this.dialogArray[this.dialogArray.length - 1].data = {
            ...this.dialogArray[this.dialogArray.length - 1].data,
            [objProp]: objVal,
          };
        } catch (err) {
          console.error(err);
        }
      }
    },
    uploadDocSuccess(estateId, docTypeProp, objVal) {
      const index = this.estatesData.findIndex((d) => {
        return d._id === estateId;
      });
      if (index != -1) {
        if (this.estatesData[index]["documents"] == undefined) {
          this.estatesData[index] = {
            ...this.estatesData[index],
            documents: {},
          };
        }
        if (this.estatesData[index]["documents"][docTypeProp])
          this.estatesData[index]["documents"][docTypeProp].push(objVal);
        else {
          let newObj = {};
          newObj[docTypeProp] = [objVal];
          this.estatesData[index]["documents"] = {
            ...this.estatesData[index]["documents"],
            ...newObj,
          };
          this.dialogArray[this.dialogArray.length - 1].data = {
            ...this.dialogArray[this.dialogArray.length - 1].data,
            documents: this.estatesData[index]["documents"],
          };
        }
      }
    },
    updateUploadedDoc(cvInfo) {
      let index = this.data.findIndex(
        (e) => e._id == this.dialogArray[this.dialogArray.length - 1].data._id
      );
      this.data[index].cv_file_data = cvInfo;
    },
    getHeight() {
      var element = document.getElementById("topAreaID");
      var positionInfo = element.getBoundingClientRect();
      this.height = positionInfo.height;
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
    onCompanyHandler(company) {
      if (company.length > 0) this.filterValues.companySelected = company;
      else this.filterValues.companySelected = null;
      this.closeDialog();
    },
  },
};
</script>

<style scoped>
.contactsCards {
  overflow: auto;
}

@media only screen and (max-width: 600px) {
  .contactsCards {
    overflow: unset !important;
  }
}

.noScrollBar::-webkit-scrollbar {
  display: none;
}

.btn {
  text-transform: unset !important;
  color: #263573;
  background-color: white;
  font-weight: 500 !important;
}

.btn-custom {
  text-transform: capitalize !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  font-size: 15px;
  background-color: white;
}

.v-btn-toggle--group > .v-btn.v-btn {
  border-color: #263573 !important;
}

.v-btn-toggle > .v-btn.v-btn {
  /* border-radius: 5px !important; */
  border-width: thin !important;
  background-color: #f8f8f8 !important;
}

#active-button {
  background-color: #263573 !important;
}

.theme--light.v-btn-toggle.v-btn-toggle--group {
  background: #f8f8f8;
  color: #263573;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) {
  background: #f8f8f8;
  color: #263573;
}

.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 36px;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: rgba(38, 53, 115, 1) !important;
  text-transform: capitalize !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  background-color: white;
}

.v-btn-toggle--group > .v-btn.v-btn {
  border-color: #263573 !important;
}

.v-btn-toggle > .v-btn.v-btn {
  border-radius: 5px !important;
  border-width: thin !important;
  background-color: white !important;
}

.custom-disabled-btn.v-btn.v-btn--disabled {
  color: unset !important;
}
</style>
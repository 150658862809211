<template>
  <v-card>
    <v-col class="pa-5">
      <v-row>
        <v-col align="right" class="pb-0">
          <v-icon @click="onCloseHandler" class="primaryColorOnHover xClass">
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col align="left" class="pt-0">
          <HeaderTitle :title="$t('choices')" :subTitle="$t('addNewPerson')" />
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col class="textDecorationBold flex-grow-0 ma-3" v-for="kind in kindList"
          :key="kind.title + ' ' + kind.selected">
          <SelectionIcon :title="kind.title" colorPicked="#263573" :isSelected="kind.selected"
            :pickHandler="onKindHandler" />
        </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>

<script>

/*
 Name:InitKind
 Description: Component to render the pop up about the contact create ask

 API Property:
  closeHandler: Function to close the slider
  onSelectHandler: Function to select a contact kind
    
  Components Used:
    SelectionIcon
    HeaderTitle
*/
import SelectionIcon from "@/components/Fields/SelectionIcon/SelectionIcon.vue";
import HeaderTitle from "../../HeaderTitle/HeaderTitle.vue";
import i18n from "@/i18n/i18n";

export default {
  name: "InitKind",
  props: {
    closeHandler: Function,
    onSelectHandler: Function,
  },
  components: {
    SelectionIcon,
    HeaderTitle,
  },
  data() {
    return {
      selectedKind: null,
      kindList: [
        { title: i18n.t("person"), value: "person", selected: false },
        // { title: "Εταιρεία", value: "company", selected: false },
      ],
    };
  },
  watch: {
    show() {
      if (!this.show) {
        this.closeHandler();
      }
    },
    selectedKind() {
      if (this.selectedKind != null) {
        this.onSelectHandler(this.selectedKind);
      }
    },
  },
  methods: {
    onCloseHandler() {
      this.closeHandler();
    },
    onKindHandler(isSelected, title) {
      if (this.selectedKind != null) {
        const oldKindIndex = this.kindList.findIndex((object) => {
          return object.title === this.selectedKind.title;
        });
        this.kindList[oldKindIndex].selected = false;
      }

      if (isSelected) {
        const newKindIndex = this.kindList.findIndex((object) => {
          return object.title === title;
        });
        this.kindList[newKindIndex].selected = true;
        this.selectedKind = this.kindList[newKindIndex];
      } else {
        this.selectedKind = null;
      }
    },
  },
};
</script>

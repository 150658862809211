<template>
  <v-card
    class=""
    min-width="292"
    min-height="155"
    max-width="292"
    max-height="155"
    style="display: flex; justify-content: center; cursor: pointer"
    :color="item.picked ? '#263573 ' : '#FFFFFF'"
    elovation="2"
    @click.native="changeColor"
  >
    <v-col class="align-self-end">
      <v-row class="mt-0 mb-0 flex-grow-0">
        <v-col class="pb-0" style="display: flex; justify-content: center">
          <v-img
            max-height="54"
            max-width="140"
            class="pa-0 ma-0"
            :src="item.picked ? whiteColor : blueColor"
          ></v-img>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="display: flex; justify-content: center pt-0">
          <span style="color: #db2928; font-size: 18px">
            {{ item.title }}
          </span>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="display: flex; justify-content: center">
          <span
            class="textDecorationBold"
            v-bind:style="
              item.picked
                ? 'color: #FFFFFF; font-size: 14px'
                : 'color: #9d9d9d; font-size: 14px'
            "
          >
            {{ item.subTitle }}
          </span>
        </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>
<script>
/* 
  Name:CompanyCard
  Description: Companie card that includes the companie logo and a title with a subtitle. When the user click the card it changes colors and image.
  API Property 
   item: Object with the companie data
   actionPicked: handler function when card clicked
*/

export default {
  name: "CompanyCard",
  props: {
    item: Object,
    actionPicked: Function, // handler function when card clicked
  },

  created() {
    this.initData();
  },
  data() {
    return {
      whiteColor: "",
      blueColor: "",
    };
  },
  methods: {
    initData() {
      this.whiteColor = require("@/assets/" + this.item.pickImagePath);
      this.blueColor = require("@/assets/" + this.item.unPickImagePath);
    },
    changeColor() {
      this.actionPicked(this.item, !this.item.picked);
    },
  },
};
</script>

<template>
    <v-navigation-drawer width="1300" right v-model="show" app temporary>
        <v-col>
            <v-row>
                <v-col class="textDecorationBold font24 primaryColor" align="left">
                    <span>
                        {{$t("activities")}}
                    </span>
                </v-col>
                <v-col class="pb-0" align="right">
                    <v-icon @click="closeHandler" class="topIcons primaryColorOnHover">
                        mdi-close
                    </v-icon>
                </v-col>
            </v-row>

            <v-row class="justify-end">
                <v-col class="flex-grow-0">
                    <v-tabs show-arrows="always" v-model="activeTab" right>
                        <v-tabs-slider style="height: 6px" color="error" />
                        <v-tab class="text-capitalize textDecorationBold tabsText">
                            {{$t("all2")}}
                        </v-tab>
                        <v-tab class="text-capitalize textDecorationBold tabsText">
                            {{$t("showcaseTaskDocuments")}}
                        </v-tab>
                        <v-tab class="text-capitalize textDecorationBold tabsText">
                            {{$t("appointment")}}
                        </v-tab>
                        <v-tab class="text-capitalize textDecorationBold tabsText">
                            {{$t("works")}}
                        </v-tab>
                        <v-tab class="text-capitalize textDecorationBold tabsText">
                            {{$t("calls")}}
                        </v-tab>
                        <v-tab class="text-capitalize textDecorationBold tabsText">
                            Emails
                        </v-tab>
                        <v-tab class="text-capitalize textDecorationBold tabsText">
                            Sms
                        </v-tab>
                    </v-tabs>
                </v-col>
                <v-col align="right" class="flex-grow-0">
                    <ActionButton :actionButtonHandler="actionButtonHandler" />
                </v-col>
            </v-row>
            <v-row class="ml-n6 mr-n6">
                <v-col class="pl-0 pr-0">
                    <v-divider />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-tabs-items v-model="activeTab">
                        <v-tab-item></v-tab-item>
                        <v-tab-item>
                            <v-row>
                                <v-col>
                                    <ActivitiesReport :items="items" :showCaseValues="showCaseValues"
                                        :updateAttribute="updateShowCaseValue" />
                                </v-col>
                                <v-col>
                                    <ShowCaseForm
                                        v-if="(activeTab == 1 && showCaseValues.selectedItem == null) || (selectedAction && selectedAction.id == 0)" />
                                    <ShowCaseView
                                        v-if="activeTab == 1 && showCaseValues.selectedItem != null && selectedAction == null"
                                        :propertyManager="propertyManager" :inSearch="inSearch"
                                        :characteristicsArray="characteristicsArray" :postedBy="postedBy"
                                        :condition="condition"  :guideDocument="guideDocument" :subTitle="subTitle" :relatedRequest="relatedRequest"
                                        :dateAndTime="dateAndTime" :creationDate="creationDate" :lastEditedDate="lastEditedDate"
                                        />
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item></v-tab-item>
                        <v-tab-item></v-tab-item>
                        <v-tab-item></v-tab-item>
                        <v-tab-item></v-tab-item>
                        <v-tab-item></v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-col>


    </v-navigation-drawer>
</template>
<script>
/* 
  Name:ActivitySlider
  Description: ActivitySlider view is a page for the user activity (tasks, appointments, calls, emails, sms).
  Components Used:
    ActivitiesReport
    ActionButton
    ShowCaseForm
    ShowCaseView

  Props:
    closeHandler: Function to close the slider from the parent
*/

import ActivitiesReport from "@/components/Activities/Report.vue";
import ActionButton from "@/components/Fields/ActionInfo/ActionButton.vue";
import ShowCaseForm from "../../components/Activities/ShowCaseForm.vue";
import ShowCaseView from "../../components/Activities/ShowCaseView.vue";
import ActivityModel from "@/static/activities/activities.js";


export default {
    name: "ActivitySlider",
    components: {
        ActivitiesReport,
        ActionButton,
        ShowCaseForm,
        ShowCaseView
    },
    props: {
        closeHandler: Function,

    },
    data() {
        return {
            show: true,
            activeTab: 0,
            showCaseValues: ActivityModel.showCaseValues,
            selectedAction: null,
            propertyManager: {
                name: 'Ανδρέας', surname: 'Σταματόπουλος', company: 'newdeal deluxe', img: '/Uploads/EstatePhotos/1661771704678.JPG'
            },
            inSearch: {
                name: 'Σταμάτης ', surname: 'Ανδρεόπουλος',
            },
            characteristicsArray: [
                {
                    image: "realEstateSilder/bedroom.png",
                    value: 6,
                },
                {
                    image: "realEstateSilder/bathroom.png",
                    value: 8,
                },
                {
                    image: "realEstateSilder/sqft.png",
                    value: '121 τ.μ.',
                },
            ],
            postedBy: {
                name: 'Ανδρέας  ', surname: 'Σταματόπουλος',
            },
            condition: "Προγραμματισμένη",
            guideDocument: "Αριθμός εγγράφου 2022",
            subTitle: "Σχόλιο Loreum Ipsum",
            relatedRequest: "Σταμάτης Ανδρεόπουλος",
            dateAndTime: "2021-05-27 | 15:37",
            creationDate: "2021-05-27 | 15:37",
            lastEditedDate: "2021-05-27 | 15:37",
            items: [
                {
                    icon: require('@/assets/activities/checkList.png'),
                    title: "Προετοιμασία Χώρου",
                    subTitle: "Υπόδειξη ενδιαφερόμενου 9/5",
                    status: "Ολοκληρώθηκε",
                    date: "9/5/2022",
                    time: "14:35",
                    flag: false
                },
                {
                    icon: require('@/assets/activities/indication.png'),
                    title: "Υπόδειξη",
                    subTitle: "Σχόλιο Loreum Ipsum",
                    status: "Εκρεμμεί Ενημέρωση",
                    date: "9/5/2022",
                    time: "15:35",
                    flag: true
                },
                {
                    icon: require('@/assets/activities/date.png'),
                    title: "Ραντεβού",
                    subTitle: "Επίσκεψη",
                    status: "Νέα",
                    date: "10/5/2022",
                    time: "08:00",
                    flag: false
                },
                {
                    icon: require('@/assets/activities/phone.png'),
                    title: "Follow up με Ανδριανόπουλο",
                    subTitle: "Σχόλιο Κλήσης",
                    status: "Νέα",
                    date: "10/5/2022",
                    time: "11:00",
                    flag: false
                },
                {
                    icon: require('@/assets/activities/mail.png'),
                    title: "Follow up με Ανδριανόπουλο",
                    subTitle: "Ραντεβού",
                    status: "Προς info@newdeal.gr",
                    date: "10/5/2022",
                    time: "11:00",
                    flag: false
                },
                {
                    icon: require('@/assets/activities/text.png'),
                    title: "SMS",
                    subTitle: "Ραντεβού",
                    status: "Προς Ευγένιος Ανδριανόπουλος",
                    date: "10/5/2022",
                    time: "11:00",
                    flag: false
                },
            ],
        };

    },
    watch: {
        show() {
            if (!this.show) {
                this.closeSimpleHandler();
            }
        },
    },
    computed: {

    },
    methods: {
        closeSimpleHandler() { this.$emit("close", false); },

        updateShowCaseValue(key, value) {
            this.showCaseValues[key] = value;
        },

        actionButtonHandler(value) {
            this.selectedAction = value;
        }
    }
}
</script>

<style scoped>
</style>
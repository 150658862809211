<template >
    <v-col>
        <v-row>
            <v-col cols="12">
                <span class="primaryColor textDecorationBold font29">{{ $t("suggestion") }}</span>
            </v-col>
            <v-col cols="12 pt-0">
                <span class="textFontDecoration font14">{{ subTitle }}</span>
            </v-col>
        </v-row>
        <v-row class="justify-center">
            <v-col class="flex-grow-0">
                <v-btn class="btn-custom error" width="173"> {{ $t("edit")}}
                </v-btn>
            </v-col>
            <v-col class="flex-grow-0">
                <v-btn class="btn-custom primary" width="173" height="40">
                    <v-icon class="pr-2"> mdi-star-outline </v-icon> {{ $t("rating") }}
                </v-btn>
            </v-col>
            <v-col class="flex-grow-0">
                <v-btn class="btn" outlined width="173" height="40">{{ $t("cancel") }}</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-row>
                    <v-col align="left">
                        <span class="textInterBold font14  primaryColor">{{ $t("propertyResponsiblePerson") }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <AvatarField :data="getPhoto(propertyManager)" :isImg="propertyManager.img ? true : false"
                            :showName="true"
                            :nameContent="(propertyManager.name ? propertyManager.name + ' ' : '') + (propertyManager.surname ? propertyManager.surname : '')"
                            fontSizeName="14px" :showSubContent="true"
                            :subContent="propertyManager.company ? propertyManager.company : ''"
                            backgroundColor="#77BD86" :size="32" fontSizeImg="14px" />
                    </v-col>
                </v-row>

            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-row>
                    <v-col align="left">
                        <span class="textInterBold font14 primaryColor">{{ $t("demand") }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <AvatarField :data="getPhoto(inSearch)" :isImg="inSearch.img ? true : false" :showName="true"
                            :nameContent="(inSearch.name ? inSearch.name + ' ' : '') + (inSearch.surname ? inSearch.surname : '')"
                            fontSizeName="14px" :showSubContent="false"
                            :subContent="inSearch.company ? inSearch.company : ''" backgroundColor="#77BD86" :size="32"
                            fontSizeImg="14px" />
                    </v-col>
                </v-row>

            </v-col>
        </v-row>
        <v-row>
            <v-col align="left" class="font14">
                <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue"
                    :fontFamilyLabel="fontFamilyLabel + ' !important; white-space: nowrap;'"
                    :fontFamilyValue="fontFamilyValue + ' !important; text-decoration: underline; color: #77BD86; white-space: nowrap; '"
                    :label="$t('relatedRequest')" :data="relatedRequest" :isUnderline="false" />
            </v-col>
        </v-row>
        <v-row>
            <v-col align="left" cols="12" class="pb-0">
                <span class="textInterBold primaryColor font14 ">{{ $t("realEstate2") }}</span>
            </v-col>
            <v-col>
                <EstateInfoCard :supportCorspondBtn="false" :supportSuggestionBtn="false" :imgHeight=112
                    :characteristicsArray="characteristicsArray" price="415.000 €" type="ΠΩΛΗΣΗ" code="ΡΣΚ62732"
                    :title="$t('blockOfFlats')" address="Κεντρική Θεσσαλονίκη, Βενιζέλου"
                    img="Uploads/EstatePhotos/1661771704678.JPG" />
            </v-col>
        </v-row>
        <v-row>
            <v-col align="left" class="font14">
                <v-row class="flex-nowrap" align="right">
                    <v-col class="flex-grow-0">
                        <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue"
                            :fontFamilyLabel="fontFamilyLabel + ' !important; white-space: nowrap;'"
                            :fontFamilyValue="fontFamilyValue + ' !important; text-decoration: underline; color: #77BD86; white-space: nowrap; '"
                            :label="$t('suggestionDocument2')" :data="guideDocument" :isUnderline="false" />
                    </v-col>
                    <v-col class="flex-grow-0 px-0">
                        <v-icon class="pr-2 primaryColorOnHover" @click="iconClick"> mdi-download </v-icon>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col align="left" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel"
                    :fontFamilyValue="fontFamilyValue" :label="$t('condition')" :data="condition"
                    :isUnderline="false" />
            </v-col>
            <v-col align="left" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel"
                    :fontFamilyValue="fontFamilyValue" :label="$t('dateAndTime')" :data="condition" :isUnderline="false" />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <HintField icon="mdi-alert-circle-outline" color="#E52628"
                    class="textInter font13 hintBackground redAlert" backgroundColor="#fce4e5 "
                    data="Εκκρεμεί αξιολόγηση." hintFontSize="13" />
            </v-col>
        </v-row>
        <v-row class="pt-4 pb-4">
            <v-col>
                <v-divider />
            </v-col>
        </v-row>
        <v-row>
            <v-col align="left" cols="12">
                <span cols="12" class="font13 textInterBold">{{ $t("submittedFrom") }}</span>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <AvatarField :data="getPhoto(postedBy)" :isImg="postedBy.img ? true : false" :showName="true"
                    :nameContent="(postedBy.name ? postedBy.name + ' ' : '') + (postedBy.surname ? postedBy.surname : '')"
                    fontSizeName="14px" :showSubContent="false" :subContent="postedBy.company ? postedBy.company : ''"
                    backgroundColor="#77BD86" :size="32" fontSizeImg="14px" />
            </v-col>
            <v-col align="left" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue"
                    :fontFamilyLabel="fontFamilyLabel + ' !important; color: #434343 !important;'"
                    :fontFamilyValue="fontFamilyValue" 
                    :label="$t('dateOfCreation')" :data="creationDate"
                    :isUnderline="false" />
            </v-col>
        </v-row>
        <v-row>
            <v-col align="left">
                <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue"
                    :fontFamilyLabel="fontFamilyLabel + ' !important; color: #434343 !important;'"
                    :fontFamilyValue="fontFamilyValue"
                    :label="$t('lastEditedDate')" :data="lastEditedDate"
                    :isUnderline="false" />
            </v-col>
        </v-row>
    </v-col>
</template>
<script>
/*
Name: ShowCaseView
Description: A page that dispalys data including images,contains muitple components,  alert and buttons

API Property:
    Data: Object containing ContactInfo data
    characteristicsArray: Array with image path and value for every property. 
        For example characteristicsArray: 
            [
                {
                    image: "realEstateSilder/bedroom.png",
                    value: 10,
                },
                {
                    image: "realEstateSilder/bathroom.png",
                    value: 20,
                },
                {
                    image: "realEstateSilder/sqft.png",
                    value: 30,
                },
            ],
    propertyManager: An Object to form avartarFields
                Example: {
                    name: 'Ανδρέας', 
                    surname: 'Σταματόπουλος', 
                    company: 'newdeal deluxe', 
                    img: '/Uploads/EstatePhotos/1661771704678.JPG'
                }
    inSearch: Object to form AvatarFields for inSearch
    postedBy: Object to form AvatarFields for Posted By
    condition: String Containing the Condition Text
    guideDocument: String Containing the guideDocument Text
    subTitle: SubTitle Text
    relatedRequest: RelatedRequest Text for ReadField,
    dateAndTime: Date and time text for ReadField
    creationDate: Creation date for ReadField
    lastEditedDate: Last Eadited Date

    Components Used:
        AvatarField
        EstateInfoCard
        HintField
        ReadField
*/
import AvatarField from '@/components/Fields/AvatarField/AvatarField.vue';
import EstateInfoCard from '@/components/Demands/Slider/EstateInfoCard.vue';
import HintField from '@/components/Fields/HintField/HintField.vue';
import ReadField from '@/components/Fields/ReadField/ReadField.vue';

export default {
    name: "ShowCaseView",
    components: { AvatarField, EstateInfoCard, HintField, ReadField },
    props: {
        characteristicsArray: Array,
        propertyManager: Object,
        inSearch: Object,
        postedBy: Object,
        condition: String,
        guideDocument: String,
        subTitle: String,
        relatedRequest: String,
        dateAndTime: String,
        creationDate: String,
        lastEditedDate: String,

    },
    data() {
        return {
            fontSizeRF: "14px", //font-size for ReadingField component
            fontFamilyLabel: "InterBold", //font-family for ReadingField component label
            fontFamilyValue: "InterMedium", //font-family for ReadingField component value
            fontSizeValue: "13px"
        }
    },
    methods: {
        getPhoto(obj) {
            return obj.img ? obj.img
                : obj.name && obj.surname ? obj.name.charAt(0) + obj.surname.charAt(0)
                    : obj.name ? obj.name.charAt(1)
                        : obj.surname ? obj.surname.charAt(1)
                            : ""
        },
        iconClick() {
        }

    },
}
</script>
<style scoped>
.btn-custom {
    text-transform: capitalize !important;
    font-family: CenturyGothicBold !important;
    font-weight: 500 !important;
    font-size: 15px;
    background-color: white;
}

.btn {
    text-transform: unset !important;
    color: #263573 !important;
    background-color: white;
    font-weight: 500 !important;
    font-family: CenturyGothicBold !important;
}

.hintBackground {
    float: left !important;
    text-align: left !important;
    font-size: 13px !important;
}
</style>

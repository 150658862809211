<template>
    <v-card>
        <v-card-title class="textDecorationBold" style="font-size: 20px; color: var(--v-primary-base)"><b> {{
        $t("areYouSure") }}</b></v-card-title>
        <v-card-text>
            <v-row>
                <v-col align="left">
                    <span class="textDecoration" style="font-size: 15px">{{ $t("deleteContact")}}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <AvatarField :data="getPhoto(item)" :isImg="item.isImgAgent ? true : false" :showName="true"
                        :nameContent="(item.FirstName ? item.FirstName + ' ' : '') + (item.LastName ? item.LastName : '')"
                        fontSizeName="14px" :showSubContent="false" :size="56"
                        fontSizeImg="14px" />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-row>
                <v-col align="center">
                    <v-btn elevation="1" align="center" @click="onCloseHandler" class="ma-1 btn textDecorationBold"
                        outlined>
                        {{ cancelText }}
                    </v-btn>

                    <v-btn elevation="1" align="center" @click="onDeleteHandler" :loading="loading"
                        class="ma-1 btn textDecorationBold" color="error">
                        {{ deleteText }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>
  
<script>
/* Name:VerifyDeleteContact
   Description: Generic delete pop up, with dynamic function to confirm delete
  
  API Property
  content: Message to user
  onRemove: Function to handle button delete
  cancelText: Label to show on button cancel
  deleteText: Label to show on button delete
  onCancel: Function to handle button cancel
  item: Current item to delete
  loading: Boolean variable to show process on back-end action
  
  Components Used:
    AvatarField
*/
import AvatarField from '@/components/Fields/AvatarField/AvatarField.vue';

export default {
    name: "VerifyDeleteContact",
    components: { AvatarField },

    props: {
        show: Boolean,
        content: String,
        onRemove: Function,
        cancelText: String,
        deleteText: String,
        onCancel: Function,
        item: Object,
        loading: Boolean,
    },
    data() {
        return {};
    },
    methods: {
        onCloseHandler() {
            this.onCancel();
            // this.$emit("close", false);
        },
        onDeleteHandler() {
            this.onRemove(this.item);
            this.onCancel();
        },
        getPhoto(obj) {
            return obj.Photo ? obj.Photo
                : obj.FirstName && obj.LastName ? obj.FirstName.charAt(0) + obj.LastName.charAt(0)
                    : obj.FirstName ? obj.FirstName.charAt(1)
                        : obj.LastName ? obj.LastName.charAt(1)
                            : ""
        },
    },
};
</script>
  
<style scoped>
.v-card__subtitle,
.v-card__text,
.v-card__title {
    padding: 0px 16px 0px 16px;
}

.v-card {
    padding: 13px 0px 13px 0px;
}

.v-card__actions {
    align-items: center;
    display: flex;
    padding: 8px 42px 8px 1px;
}

.btn {
    text-transform: unset !important;
    color: #263573;
}
</style>
  
<template>
    <v-row>
        <v-col class="flex-grow-0">
            <v-img :src="icon"> </v-img>
        </v-col>
        <v-col>
            <v-row>
                <v-col align="left" class="pb-0 cusTab font14 textDecorationBold text-no-wrap">
                    <span> {{ title }} </span>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="left" class="pt-0 cusTab font14 text-no-wrap">
                    <span>{{ subTitle }} </span>
                </v-col>
            </v-row>
        </v-col>
        <v-col :class="flag == true ? 'errorColorBack' : ''">
            <v-row>
                <v-col align="right" class="pb-0 font14 errorColor text-no-wrap">
                    <span> {{ status }} </span>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="right" class="pt-0 cusTab font14 text-no-wrap">
                    <span> {{ date + " | " + time }} </span>
                </v-col>
            </v-row>
        </v-col>
        <v-col class="flex-grow-0 align-self-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="6.937" height="8.387" viewBox="0 0 6.937 8.387">
                <path id="Rectangle_3_copy" data-name="Rectangle 3 copy"
                    d="M4.713,8.021.74,5.321a1.3,1.3,0,0,1-.358-.244,1.214,1.214,0,0,1,0-1.767A1.3,1.3,0,0,1,.74,3.067L4.713.365a1.343,1.343,0,0,1,1.842,0,1.214,1.214,0,0,1,0,1.767L3.523,4.193,6.556,6.254a1.214,1.214,0,0,1,0,1.767,1.343,1.343,0,0,1-1.842,0Z"
                    transform="translate(6.937 8.387) rotate(180)" fill="#263573" />
            </svg>

        </v-col>
    </v-row>
</template>

<script>
/*
 Name:ShowCase
 Description: Component to render a list of cases for a contact

 API Property:
    icon: String for the icon path
    title: String for the title
    subTitle: String for the subtitle
    status: String for the status
    date: String for the case date
    time: String for the time
    flag: Boolean for the backround color. If value is false then the color is red otherwise white 
*/
export default {
    name: "ShowCase",
    props: {
        icon: String,
        title: String,
        subTitle: String,
        status: String,
        date: String,
        time: String,
        flag: { type: Boolean, default: false }, //If the backround color is red or not 
    },
}
</script>

<style scoped>
.errorColorBack {
    background-color: rgba(229, 38, 41, 0.12)
}
</style>
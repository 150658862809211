var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticStyle:{"height":"100%"},attrs:{"width":"866px","fixed":"","right":"","app":"","temporary":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-col',{staticClass:"px-12"},[_c('v-row',{staticClass:"justify-end"},[_c('v-col',{staticClass:"pb-0 flex-grow-0"},[_c('v-icon',{staticClass:"primaryColorOnHover xClass",on:{"click":_vm.onCloseHandler}},[_vm._v(" mdi-close ")])],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('HeaderTitle',{attrs:{"title":_vm.$t('addNewContact')}})],1),_c('v-row',[_c('v-col',{staticClass:"text-capitalize font14",attrs:{"align":"left"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("chooseCategory") + "*")+" ")])])],1),_c('v-row',{staticClass:"justify-start mt-6"},_vm._l((_vm.filteredAvailableRoles),function(role){return _c('v-col',{key:role.title + ' ' + role.selected,attrs:{"cols":"6","xl":"3","lg":"3","md":"3","sm":"3","xs":"6"}},[_c('SelectionIcon',{attrs:{"title":role.title,"colorPicked":"#263573","isSelected":role.selected,"pickHandler":_vm.onCategoryHandler}})],1)}),1),(
        _vm.selectedRole &&
        _vm.selectedRole.value &&
        ['Admin', 'Broker', 'Secretary', 'Associate', 'OfficeAdmin'].includes(
          _vm.selectedRole.value
        )
      )?_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"font14",attrs:{"align":"left"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("selectNetwork") + " " + _vm.selectedRole.label))]),_c('span',{staticClass:"errorColor"},[_vm._v(" "+_vm._s(_vm.selectedCompanyErrors[0]))])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"align":"left"}},[_c('v-btn',{key:"fBtn0",staticClass:"btn-custom unsetCapitalize textDecorationBold",attrs:{"outlined":"","color":"primary"},on:{"click":function () { return _vm.openDialog('company', null); }}},[_vm._v(_vm._s(_vm.$t("companySelection"))+" "),(_vm.selectedCompany != null)?_c('span',[_vm._v(" : "+_vm._s(_vm.selectedCompany.title + " " + _vm.selectedCompany.subTitle))]):_vm._e()])],1)],1)],1)],1):_vm._e(),(
        _vm.selectedRole &&
        _vm.selectedRole.value &&
        ['Associate'].includes(_vm.selectedRole.value)
      )?_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"font14 pb-0",attrs:{"align":"left"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("itsAbout"))+" "),_c('span',{staticClass:"textDecorationBold"},[_vm._v(" Team Director ")]),_vm._v(" "+_vm._s("(" + _vm.$t("teamManager") + "):")+" ")]),_c('span',{staticClass:"errorColor"},[_vm._v(" "+_vm._s(_vm.isTeamDirectorErrors[0]))])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('RadioField',{attrs:{"val":_vm.isTeamDirector,"fieldName":"isTeamDirector","isRow":true,"options":_vm.yesOrNoFields,"radioValues":"yesOrNoFields","radioChangeHandler":_vm.updateRadioField}})],1)],1)],1)],1):_vm._e(),(
        _vm.selectedRole &&
        _vm.selectedRole.value &&
        ['owner'].includes(_vm.selectedRole.value)
      )?_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"font14 pb-0",attrs:{"align":"left"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("costumerOwner"))+" ")]),_c('span',{staticClass:"errorColor"},[_vm._v(" "+_vm._s(_vm.customerKindErrors[0]))])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 textDecorationBold font14"},_vm._l((_vm.customerKinds),function(cusKind,i){return _c('v-checkbox',{key:i,attrs:{"label":cusKind.label,"hide-details":"","color":"warning","on-icon":"mdi-check-circle","off-icon":"mdi-checkbox-blank-circle-outline"},on:{"change":_vm.cusKindChanged},model:{value:(cusKind.value),callback:function ($$v) {_vm.$set(cusKind, "value", $$v)},expression:"cusKind.value"}})}),1)],1)],1)],1):_vm._e(),(
        _vm.selectedRole &&
        _vm.selectedRole.value &&
        ['Secretary'].includes(_vm.selectedRole.value)
      )?_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"font14 pb-0",attrs:{"align":"left"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("stallRole") + ":")+" ")]),_c('span',{staticClass:"errorColor"},[_vm._v(" "+_vm._s(_vm.secrTypeErrors[0]))])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('RadioField',{attrs:{"options":_vm.secrTypes,"radioValues":"secrTypes","radioChangeHandler":_vm.updateRadioField,"val":_vm.secrType,"fieldName":"secrType","isRow":false}})],1)],1)],1)],1):_vm._e(),_c('v-footer',{staticStyle:{"background-color":"white"},attrs:{"absolute":""}},[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"align":"left"}},[_c('v-btn',{key:"fBtn0",staticClass:"btn-custom unsetCapitalize textDecorationBold",class:_vm.selectedRole != null ? 'whiteLetters' : '',attrs:{"color":_vm.selectedRole != null ? 'red' : 'primary',"disabled":_vm.selectedRole == null},on:{"click":_vm.createContact}},[_vm._v(" "+_vm._s(_vm.$t("createNewContact"))+" ")])],1)],1)],1)],1),(_vm.dialog.type == 'company')?_c('v-dialog',{staticClass:"pa-6",model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[_c('CompaniesModal',{attrs:{"actionHandler":_vm.onCompanyHandler,"multi":false}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
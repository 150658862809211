<template>
    <v-row>
        <v-card :elevation="0">
            <v-list>
                <v-list-item-group v-model="selectedItem" color="primary">
                    <v-list-item v-for="(items, i) in items" :key="i" :class="selectedItem == i ? 'selected' : 'notSelected'">
                        <v-list-item-content>
                            <ShowCase :icon="items.icon" :title="items.title" :subTitle="items.subTitle"
                                :status="items.status" :date="items.date" :time="items.time" :flag="items.flag" />
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
    </v-row>
</template>

<script>
/*
 Name:Report
 Description: Component to render a list of items based title and status

 API Property:
    items: Array of objects for items
    showCaseValues: Object to return the selected items
    updateAttribute: Function from the parent to update the data

 Components Used:
    ShowCase
*/
import ShowCase from "@/components/Activities/ShowCase.vue";
export default {
    name: "ActivitiesReport",
    components: {
        ShowCase,
    },
    props: {
        items: Array,//Array of object  
        showCaseValues: Object,
        updateAttribute: Function,
    },
    data() {
        return {
            //selectedItem: null,
        }
    },
    computed: {
        selectedItem: {
            get() {
                return this.showCaseValues?.selectedItem;
            },
            set(value) {
                this.updateAttribute("selectedItem", value);
            },
        },
    }
}
</script>

<style scoped>
.selected {
    border-bottom: 1px solid #DEE0E9;
}

.notSelected {
    border-bottom: 1px solid #DEE0E9;
    border-right: 1px solid #DEE0E9;
}
</style>
<template>
  <v-navigation-drawer
    width="360px"
    style="height: 100%; position: fixed"
    right
    v-model="show"
    app
    temporary
  >
    <v-col>
      <v-row class="justify-between">
        <v-col class="pb-0" align="right">
          <v-icon @click="closeHandler" class="topIcons primaryColorOnHover">
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
      <v-row class="middleRow">
        <v-col class="pl-0 pr-0">
          <v-list-item class="pl-0 pr-0">
            <v-expansion-panels v-model="openPanel" accordion>
              <v-expansion-panel>
                <v-expansion-panel-header style="background-color: #ebebeb">
                  <v-row>
                    <v-col>
                      <h5
                        class="textDecorationBold primaryColor"
                        style="font-size: 16px !important"
                      >
                        {{$t("filters")}}
                      </h5>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>

                <v-expansion-panel-content align="left" class="panelOverflow">
                  <v-row></v-row>
                  <v-row class="ml-6 mr-6">
                    <v-col class="pl-0 pr-0"> <v-divider /> </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6
                        style="font-size: 14px !important"
                        class="text-uppercase textDecorationBold"
                      >
                        {{$t("profileΙnformation")}}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="firstname"
                        :label="$t('firstname')"
                        clearable
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="lastname"
                        :label="$t('lastname')"
                        clearable
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="telephone"
                        label="Τηλέφωνο"
                        clearable
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="email"
                        label="Email"
                        clearable
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="suffix"
                        :label="$t('collaboratorCode')"
                        clearable
                        color="primary"
                        hide-details="auto"
                        v-on:keyup.enter="onSetFilter"
                      />
                    </v-col>
                  </v-row>
                  <!-- <v-row class="ml-n6 mr-n6 mt-0">
                    <v-col class="pl-0 pr-0"> <v-divider /> </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6
                        style="font-size: 14px !important"
                        class="text-uppercase textDecorationBold"
                      >
                        ΕΜΦΑΝΙΣΗ
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" class="pt-0 pb-0">
                      <v-checkbox
                        v-model="deleteContacts"
                        label="Μόνο διεγραμμένες επαφές"
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12" class="pt-0 pb-0">
                      <v-checkbox
                        v-model="contactUser"
                        label="Η επαφή έχει λογαριασμό χρήστη"
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12" class="pt-0 pb-0">
                      <v-checkbox
                        v-model="contactEstate"
                        label="Η επαφή έχει ακίνητα"
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12" class="pt-0 pb-0">
                      <v-checkbox
                        v-model="contactRequest"
                        label="Η επαφή έχει ενεργές ζητήσεις"
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12" class="pt-0 pb-2 ">
                      <v-checkbox
                        v-model="contactNewDeal"
                        label="Έχει προσωπική σελίδα newdeal"
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row> -->
                  <v-row
                    v-if="
                      ['Admin', 'Broker', 'Secretary', 'Leader', 'OfficeAdmin'].includes(
                        this.userDetails.Role
                      )
                    "
                  >
                    <v-col>
                      <v-row class="ml-n6 mr-n6 mt-0">
                        <v-col class="pl-0 pr-0"> <v-divider /> </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-0 pb-0">
                          <h6
                            style="font-size: 14px !important"
                            class="text-uppercase textDecorationBold"
                          >
                            ADMIN TOOLS
                          </h6>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          xl="12"
                          lg="12"
                          md="12"
                          sm="12"
                          xs="12"
                          class="pt-0 pb-0 mt-2 mb-n3"
                        >
                          <v-autocomplete
                            v-model="agent_customers"
                            :label="$t('assignedTo')"
                            :items="agentsList"
                            :filter="filterAgents"
                            item-value="_id"
                            item-text="fullname"
                            return-object
                            :search-input.sync="searchAgent"
                            :loading="autocompleteLoaderAgent"
                            hide-no-data
                            flat
                            clearable
                            attach
                            hide-selected
                            v-on:keyup.enter="onSetFilter"
                          >
                            <template v-slot:selection="{ item }">
                              <v-col class="flex-grow-0 text-no-wrap pa-0">
                                <span>
                                  {{ item.LastName + " " + item.FirstName }}
                                </span>
                              </v-col>
                            </template>
                            <template slot="item" slot-scope="data">
                              <v-list>
                                <v-list-item-title>
                                  <v-row>
                                    <v-col>
                                      <span>
                                        {{
                                          data.item.LastName +
                                          " " +
                                          data.item.FirstName
                                        }}
                                      </span>
                                    </v-col>
                                  </v-row>
                                </v-list-item-title>
                              </v-list>
                            </template>
                          </v-autocomplete>
                        </v-col>

                        <!-- <v-col class="pt-0" cols="12" sm="12">
                          <v-checkbox
                            v-model="newsletter"
                            label="Δεν λαμβάνει newsletter"
                            hide-details
                          >
                          </v-checkbox>
                        </v-col> -->
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6 mt-3">
                    <v-col class="pl-0 pr-0"> <v-divider /> </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6
                        style="font-size: 14px !important"
                        class="text-uppercase textDecorationBold"
                      >
                        {{$t('systemAddDate')}}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-0 pr-0 pl-1 dateClearable">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            outlined
                            :placeholder="$t('from')"
                            clearable
                            v-model="createdDateFromFormat"
                            prepend-inner-icon="mdi-calendar"
                            @blur="
                              createdDateFrom = parseDate(
                                createdDateFromFormat,
                                'createdDateFrom'
                              )
                            "
                            v-on:keyup.enter="onSetFilter"
                          >
                            <template v-slot:prepend-inner>
                              <v-icon v-bind="attrs" v-on="on"
                                >mdi-calendar</v-icon
                              >
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          locale="el"
                          v-model="createdDateFrom"
                          scrollable
                          @input="menu = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0 pb-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-1 pb-0 pl-0 pr-1 dateClearable">
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        left
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            outlined
                            clearable
                            :placeholder="$t('toFilter')"
                            v-model="createdDateToFormat"
                            prepend-inner-icon="mdi-calendar"
                            @blur="
                              createdDateTo = parseDate(
                                createdDateToFormat,
                                'createdDateTo'
                              )
                            "
                            v-on:keyup.enter="onSetFilter"
                          >
                            <template v-slot:prepend-inner>
                              <v-icon v-bind="attrs" v-on="on"
                                >mdi-calendar</v-icon
                              >
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          locale="el"
                          v-model="createdDateTo"
                          scrollable
                          @input="menu1 = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-list-item>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-footer padless absolute style="background-color: #ebebeb">
            <v-row class="pt-5 pb-5">
              <v-col>
                <v-btn
                  elevation="5"
                  align="center"
                  class="ma-1 btn"
                  color="error"
                  @click="onSetFilter"
                >
                  {{ $t("search") }}
                </v-btn>
              </v-col>
              <v-col class="align-self-center" aling="center">
                <a
                  class="text-decoration-underline errorColor"
                  @click="clearFiltersHandler"
                >
                  {{ $t("clear") }}</a
                >
              </v-col>
            </v-row>
          </v-footer>
        </v-col>
      </v-row>
    </v-col>
  </v-navigation-drawer>
</template>

<script>
/*
Name:ContactFilters
Description: Component to render filters for the contacts

API Property:
    onCloseHandler: Function to close the slider
    filterValues: Object with the filter values to filter
    updateAttribute: Function to update the data
    onSearch: Function for searching

    Components Used:
      HeaderTitle
      Upload
      Location 

    Endpoints Functions Called:
    -ContactRepo
      getAgents
*/
const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};
import { mapState } from "vuex";
import CoreHelper from "@/helpers/Core/core";
import Repository from "@/services/Repository";

const ContactRepo = Repository.get("contacts");
export default {
  name: "ContactFilters",
  props: {
    onCloseHandler: Function,
    filterValues: Object,
    updateAttribute: Function,
    onSearch: Function,
  },
  data() {
    return {
      show: true,
      isRefresh: false,
      openPanel: 0,
      menu: false,
      menu1: false,
      createdDateToFormat: null,
      createdDateFromFormat: null,
      agentsList: [],
      searchAgent: null,
      autocompleteLoaderAgent: false,
    };
  },
  watch: {
    show() {
      if (this.show == false) this.onCloseHandler();
    },
    createdDateFrom() {
      this.createdDateFromFormat = this.formatDate(this.createdDateFrom);
    },
    createdDateTo() {
      this.createdDateToFormat = this.formatDate(this.createdDateTo);
    },
    searchAgent(val) {
      val && val != this.agent && this.fetchAgentsList(val);
    },
  },
  async created() {
    await this.fetchAgentsList(null);
    this.createdDateFromFormat = this.formatDate(this.createdDateFrom);
    this.createdDateToFormat = this.formatDate(this.createdDateTo);
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.auth.userDetails,
    }),
    firstname: {
      get() {
        return this.filterValues?.FirstName;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("FirstName", value);
      },
    },
    lastname: {
      get() {
        return this.filterValues.LastName;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("LastName", value);
      },
    },
    telephone: {
      get() {
        return this.filterValues.Telephone;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("Telephone", value);
      },
    },
    email: {
      get() {
        return this.filterValues.Email;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("Email", value);
      },
    },
    suffix: {
      get() {
        return this.filterValues.Suffix;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("Suffix", value);
      },
    },
    // deleteContacts: {
    //   get() {
    //     return this.filterValues.delteContacts;
    //   },
    //   set(value) {
    //     this.updateAttribute("deleteContacts", value);
    //   },
    // },
    // contactUser: {
    //   get() {
    //     return this.filterValues.contactUser;
    //   },
    //   set(value) {
    //     this.updateAttribute("contactUser", value);
    //   },
    // },
    // contactEstate: {
    //   get() {
    //     return this.filterValues.contactEstate;
    //   },
    //   set(value) {
    //     this.updateAttribute("contactEstate", value);
    //   },
    // },
    // contactRequest: {
    //   get() {
    //     return this.filterValues.contactRequest;
    //   },
    //   set(value) {
    //     this.updateAttribute("contactRequest", value);
    //   },
    // },
    // contactNewDeal: {
    //   get() {
    //     return this.filterValues.contactNewDeal;
    //   },
    //   set(value) {
    //     this.updateAttribute("contactNewDeal", value);
    //   },
    // },

    agent_customers: {
      get() {
        return this.filterValues.agent_customers;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("agent_customers", value);
      },
    },

    newsletter: {
      get() {
        return this.filterValues.newsletter;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("newsletter", value);
      },
    },
    createdDateFrom: {
      get() {
        return this.filterValues.createdDateFrom;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("createdDateFrom", value);
      },
    },
    createdDateTo: {
      get() {
        return this.filterValues.createdDateTo;
      },
      set(value) {
        this.isRefresh = true;
        this.updateAttribute("createdDateTo", value);
      },
    },
  },
  methods: {
    closeHandler() {
      this.onCloseHandler();
    },
    onSetFilter() {
      this.onSearch(this.isRefresh);
    },
    filterAgents(item, queryText, itemText) {
      if (queryText.length >= 1) {
        let queryWord = queryText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .split(" ");
        let itemWord = itemText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase();
        return queryWord.every((item) => itemWord.includes(item));
      }
    },
    clearFiltersHandler() {
      this.FirstName = null;
      this.LastName = null;
      this.Telephone = null;
      this.Email = null;
      this.Suffix = null;
      this.deteleContacts = false;
      this.contactUser = false;
      this.contactEstate = false;
      this.contactRequest = false;
      this.contactNewDeal = false;
      this.assigneeTo = null;
      this.newsletter = false;
      this.createdDateFrom = null;
      this.createdDateTo = null;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "createdFrom") {
          this.createdDateFrom = null;
          this.createdDateFromFormat = null;
        } else if (dateName == "createdTo") {
          this.createdDateTo = null;
          this.createdDateToFormat = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async fetchAgentsList(val) {
      let filters;
      let resp;
      // if (this.userDetails.Role == "Leader") {
      //   filters = {
      //     _id: { $in: [...this.userDetails.Team] },
      //   };
      // } else {
      filters = [
        { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
      ];
      // }

      try {
        if (val != null) {
          if (filters.length == 1) {
            filters.push([]);
          }
          filters[1].push({
            ["LastName"]: {
              $regex: CoreHelper.toRegex(val.split(" ")[0]),
              $options: "i",
            },
          });
        }
        resp = await ContactRepo.getAgents(filters, {
          page: 0,
          items: 60,
        });
        this.agentsList = resp.data.agents.map((a) => {
          return { ...a, fullname: a.LastName + " " + a.FirstName };
        });
        // }

        this.autocompleteLoaderAgent = false;
      } catch (e) {
        this.autocompleteLoaderAgent = false;
        throw e;
      }
    },
  },
};
</script>

<style scoped>
.v-expansion-panel--active + .v-expansion-panel,
.v-expansion-panel--active:not(:first-child) {
  margin-top: 0px;
}
.panelOverflow {
  height: calc(100vh);
  overflow: auto;
}
.middleRow {
  max-height: calc(100vh - 120px);
  overflow: auto;
}
.primaryColor {
  color: #263573 !important;
}
</style>

<template>
  <v-menu offset-y left nudge-width="173" nudge-height="178">
    <template v-slot:activator="{ on }">
      <v-btn
        :min-width="btnWidth"
        :min-height="btnHeight"
        :max-width="btnWidth"
        :max-height="btnHeight"
        outlined
        v-on="on"
        class="pa-0 mt-1"
        :hover="true"
        @click="pressedHandler()"
        :style="{
          backgroundColor: currVal ? 'white !important' : colorPicked,
          borderColor: colorPicked,
        }"
      >
        <v-img
          class="pt-0 ma-1"
          :min-width="imageWidth"
          :min-height="imageHeight"
          :max-width="imageWidth"
          :max-height="imageHeight"
          :src="currVal ? pickImage : unPickImage"
        ></v-img>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item
          v-for="index in addChoices"
          :key="index.id"
          @click="menuActionClick(index)"
        >
          <v-list-item-content>
            <v-list-item-title>
              <span class="textDecorationBold" style="font-size: 14px">
                {{ $t(index.name) }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>
<script>
/*
  Name:ActionButton
  Description: ActionButton is a component with a v-btn that change color when is pressed and a v-menu with a list of choices to select.
  API Property
    unPickPathImage = unpicked image path
    pickPathImage = picked image path
    imageWidth = picked/unpicked image width
    imageHeight = picked/unpicked image height
    btnWidth = v-btn width
    btnHeight = v-btn height
    colorPicked = color in Hex when image picked
    actionButtonHandler = Function for the parent that returns the selected item from the list
*/
export default {
  name: "ActionButton",
  props: {
    unPickPathImage: { type: String, default: "actionInfo/white+.png" }, // unpicked image path
    pickPathImage: { type: String, default: "actionInfo/red+.png" }, // picked image path
    imageWidth: { type: Number, default: 12 }, // picked/unpicked image width
    imageHeight: { type: Number, default: 12 }, // picked/unpicked image height
    btnWidth: { type: Number, default: 23 }, // v-btn width
    btnHeight: { type: Number, default: 23 }, // v-btn height
    colorPicked: { type: String, default: "#E52628" }, // color in Hex when image picked
    actionButtonHandler: Function, //Function for the parent
  },
  created() {
    this.initData();
  },
  data() {
    return {
      selectedItem: 1,
      currVal: false,
      pickImage: "",
      unPickImage: "",
      addChoices: [
        {
          id: "0",
          type: "suggestion",
          name: "suggestion",
        },
        {
          id: "1",
          type: "task",
          name: "Task",
        },
        {
          id: "2",
          type: "call",
          name: "call",
        },
        {
          id: "3",
          type: "email",
          name: "email",
        },
        {
          id: "4",
          type: "sms",
          name: "Sms",
        },
      ],
    };
  },
  computed: {},
  methods: {
    pressedHandler() {
      this.currVal = !this.currVal;
    },
    initData() {
      this.pickImage = require("@/assets/" + this.pickPathImage);
      this.unPickImage = require("@/assets/" + this.unPickPathImage);
    },
    menuActionClick(type) {
      this.actionButtonHandler(type);
    },
  },
};
</script>

<style scoped></style>

<template>
  <v-card
    max-width="292"
    min-width="292"
    max-height="300"
    min-height="300"
    @click.prevent="cardClicked()"
    :ripple="false"
    class="reportCardClass cardContent"
  >
    <v-col>
      <v-row class="topRow justify-space-between">
        <v-col class="flex-grow-0 pb-0">
          <v-checkbox
            class="rounded-circle mt-0 mb-0"
            v-if="supportSelect == true"
            @click="$event.stopPropagation()"
            v-model="selectedVal"
            :disabled="!supportSelect"
            off-icon="mdi-checkbox-blank-circle-outline"
            on-icon="mdi-checkbox-marked-circle"
          />
        </v-col>
        <v-col class="flex-grow-0 pb-0">
          <v-row class="flex-nowrap">
            <v-col v-if="isFavorite == true" class="pl-0 pb-0">
              <v-icon color="red" size="22"> mdi-cards-heart </v-icon>
            </v-col>
            <v-col class="pl-0 pr-0 pt-2 pb-0" v-if="authorizedContact == true">
              <v-btn
                icon
                v-if="supportEdit == true"
                @click.stop="onEdit"
                :ripple="false"
                class="primaryColorOnHover"
              >
                <v-icon> mdi-pencil-outline </v-icon>
              </v-btn>
            </v-col>
            <v-col class="pl-0 pr-1 pt-2 pb-0" v-if="authorizedContact == true">
              <v-btn
                icon
                v-if="supportDelete == true"
                @click.stop="onDelete"
                :ripple="false"
                class="primaryColorOnHover"
              >
                <v-icon> mdi-delete-outline </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="midRow">
        <v-col class="pt-0">
          <v-row>
            <v-col class="pt-0">
              <v-avatar :color="backgroundColor" :size="size">
                <span
                  class="white--text"
                  style="font-family: Inter; font-size: 206.25%"
                  :style="' font-size:' + fontSizeImg"
                  v-if="!isImg"
                >
                  {{ photo }}
                </span>
                <v-img
                  v-else
                  :src="getImage(photo)"
                  :lazy-src="getImage(photo)"
                  alt="John"
                />
              </v-avatar>
            </v-col>
          </v-row>
          <v-row v-if="title">
            <v-col class="textDecorationBold titleColor font16 pt-0 pb-1">
              <span> {{ title }} </span>
            </v-col>
          </v-row>
          <v-row v-if="subTitle">
            <v-col class="subTitleColor font14 pt-0">
              <span> {{ subTitle }} </span>
            </v-col>
          </v-row>
          <v-row v-if="bottomTitle">
            <v-col v-if="coloredTextBox" class="font13 pt-0">
              <span style="color: #263573">
                {{
                  bottomTitle.split(" ")[0] != undefined
                    ? bottomTitle.split(" ")[0]
                    : ""
                }}</span
              >
              <span style="color: #e52628">
                {{
                  bottomTitle.substring(bottomTitle.indexOf(" ") + 1) !=
                  undefined
                    ? bottomTitle.substring(bottomTitle.indexOf(" ") + 1)
                    : ""
                }}
              </span>
            </v-col>
            <v-col v-else class="font13 pt-0">
              <span> {{ bottomTitle }} </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pa-0">
          <v-divider />
        </v-col>
      </v-row>
      <v-row class="botRow">
        <v-col
          v-if="supportLeftBtn == true && leftBtnObj"
          class="px-0 pb-2 pt-0"
        >
          <v-btn
            id="no-background-hover"
            :width="leftBtnObj.width ? leftBtnObj.width : ''"
            :height="leftBtnObj.height ? leftBtnObj.height : 31"
            :color="leftBtnObj.color ? leftBtnObj.color : ''"
            :class="leftBtnObj.class ? leftBtnObj.class : ''"
            :style="leftBtnObj.style ? leftBtnObj.style : ''"
            style="text-transform: capitalize !important; height: 100%"
            elevation="0"
            @click.stop="leftBtnClicked()"
          >
            <v-row class="justify-center flex-nowrap">
              <v-col
                class="flex-grow-0 align-self-center flex-grow-0 text-no-wrap"
                v-if="
                  leftBtnObj.showIcon &&
                  leftBtnObj.iconObj &&
                  leftBtnObj.iconObj.icon
                    ? leftBtnObj.showIcon
                    : false
                "
                :class="
                  leftBtnObj &&
                  leftBtnObj.showIcon &&
                  leftBtnObj.iconObj &&
                  leftBtnObj.iconObj.class
                    ? leftBtnObj.iconObj.class
                    : ''
                "
              >
                <Icon
                  :icon="leftBtnObj.iconObj.icon"
                  :height="
                    leftBtnObj.iconObj.height ? leftBtnObj.iconObj.height : '30'
                  "
                  :color="
                    leftBtnObj.iconObj.color
                      ? leftBtnObj.iconObj.color
                      : 'white'
                  "
                />
              </v-col>
              <v-col
                align="left"
                class="pl-0 align-self-center flex-grow-0 text-no-wrap"
              >
                {{ leftBtnObj.description ? leftBtnObj.description : "" }}
              </v-col>
            </v-row>
          </v-btn>
        </v-col>
        <v-col
          v-if="
            supportLeftBtn == true &&
            leftBtnObj &&
            supportRightBtn == true &&
            rightBtnObj
          "
          class="px-1 pb-2 pt-0 flex-grow-0"
        >
          <v-divider vertical />
        </v-col>
        <v-col
          v-if="supportRightBtn == true && rightBtnObj"
          class="px-0 pb-2 pt-0"
        >
          <v-btn
            id="no-background-hover"
            :width="rightBtnObj.width ? rightBtnObj.width : ''"
            :height="rightBtnObj.height ? rightBtnObj.height : 31"
            :color="rightBtnObj.color ? rightBtnObj.color : ''"
            :class="rightBtnObj.class ? rightBtnObj.class : ''"
            :style="rightBtnObj.style ? rightBtnObj.style : ''"
            style="text-transform: capitalize !important; height: 100%"
            elevation="0"
            @click.stop="rightBtnClicked()"
          >
            <v-row class="justify-center flex-nowrap">
              <v-col
                class="
                  flex-grow-0
                  text-no-wrap
                  align-self-center
                  pl-2
                  pr-2
                  pt-4
                "
                v-if="
                  rightBtnObj.showIcon &&
                  rightBtnObj.iconObj &&
                  rightBtnObj.iconObj.icon
                    ? rightBtnObj.showIcon
                    : false
                "
                :class="
                  rightBtnObj &&
                  rightBtnObj.showIcon &&
                  rightBtnObj.iconObj &&
                  rightBtnObj.iconObj.class
                    ? rightBtnObj.iconObj.class
                    : ''
                "
              >
                <Icon
                  :icon="rightBtnObj.iconObj.icon"
                  :height="
                    rightBtnObj.iconObj.height
                      ? rightBtnObj.iconObj.height
                      : '30'
                  "
                  :color="
                    rightBtnObj.iconObj.color
                      ? rightBtnObj.iconObj.color
                      : 'white'
                  "
                />
              </v-col>
              <v-col
                align="left"
                class="pl-0 align-self-center flex-grow-0 text-no-wrap"
              >
                {{ rightBtnObj.description ? rightBtnObj.description : "" }}
              </v-col>
            </v-row>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>
<script>
/* 
  Name:PersonCard
  Description: Person component to generate quickly Request cards. Support approve buttons with actions, custom button with custom actions, show unread indication, selection of card, shows basic data and onClick card actions
  API Property   
  index: Number containing index of object in array
  isFavorite: Boolean variable to set as favorite
  supportEdit: Boolean variable to show or hide edit button
  onEditHandler: Handler function from parent when edit is clicked
  isDeleted:  Boolean variable to set deleted status
  supportDelete: Boolean variable to show or hide delete button
  onDeleteHandler: Handler function from parent when delete is clicked
  supportSelect: Boolean variable to enable or disable delete button
  selected: Boolean variable for selected value
  onSelectHandler: Handler function from parent when card is selected
  photo: Array containing photos
  title: String containing the title of the card,
  bottomTitle: String containing the bottom title of the card,
  subTitle: String or Array containing the subtitle of the card,
  rating: Number or String containing rating data,
  content: HTML containing the content data,
  backgroundColor: Background color for the v-avatar,
  fontSizeImg: String size for the image letter if there is no image,
  coloredTextBox: Boolean for double color subTitle
  size: v-avatar size,
  supportLeftBtn: Boolean variable to show/hide the Left Button
  leftBtnObj: Object containing custom button details. For example:
                leftBtnObj: { description: "Μήνυμα", style: "font-size: 14px; background-color: #ffffff !important;", class: "", showIcon: true, width:"100%", iconObj: { icon: "charm:message", height: 16, color: "green", class: "", }, },
  leftBtnHandler: Handler function from parent when left button clicked
  supportRightBtn: Boolean variable to show/hide the Left Button
  rightBtnObj: Object containing custom button details. For example:
                rightBtnObj: { description: "Κλήση", style: "font-size: 14px; background-color: #ffffff !important;", class: "", showIcon: true, width:"100%", iconObj: { icon: "mdi:phone-outline",, height: 16, color: "green", class: "", }, },
  rightBtnHandler: Handler function from parent when left button clicked
  onClickHandler:  Handler function from parent when card is clicked,

  Components Used:
    Icon
*/
import CoreHelper from "@/helpers/Core/core.js";
import ContactsHelper from "@/helpers/Contacts/contacts.js";
import { Icon } from "@iconify/vue2";
import i18n from "@/i18n/i18n.js";
export default {
  name: "PersonCard",
  props: {
    index: Number,
    isFavorite: { type: Boolean, default: false },
    supportEdit: { type: Boolean, default: false },
    onEditHandler: Function,
    isDeleted: { type: Boolean, default: false },
    supportDelete: { type: Boolean, default: false },
    isImg: { type: Boolean, default: false },
    onDeleteHandler: Function,
    supportSelect: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
    coloredTextBox: { type: Boolean, default: false },
    onSelectHandler: Function,
    photo: [String, Array],
    title: String,
    subTitle: [String, Array],
    rating: [Number, String],
    bottomTitle: String,
    content: String,
    role: String,
    type: [String, Array],
    lastCompany: String,
    supportLeftBtn: { type: Boolean, default: true },
    backgroundColor: { type: String, default: "#72B681" },
    fontSizeImg: { type: [String, Number], default: "54px" },
    size: { type: [String, Number], default: "100" },
    leftBtnObj: {
      type: Object,
      default() {
        return {
          description: i18n.t("message"),
          style: "font-size: 14px; background-color: #ffffff !important;",
          class: "",
          showIcon: true,
          width: "100%",
          iconObj: {
            icon: "charm:message",
            height: 16,
            color: "#77BD86",
            class: "",
          },
        };
      },
    },
    leftBtnHandler: Function,
    supportRightBtn: { type: Boolean, default: true },
    rightBtnObj: {
      type: Object,
      default() {
        return {
          description: i18n.t("call"),
          style: "font-size: 14px; background-color: #ffffff !important;",
          class: "",
          showIcon: true,
          width: "100%",
          iconObj: {
            icon: "mdi:phone-outline",
            height: 16,
            color: "#77BD86",
            class: "",
          },
        };
      },
    },
    rightBtnHandler: Function,
    onClickHandler: Function,
  },
  components: {
    Icon,
  },
  computed: {
    selectedVal: {
      get() {
        return this.selected;
      },
      set(value) {
        this.selectedHandler(this.index, value);
      },
    },

    authorizedContact() {
      return this.isAuthorizedContact({
        role: this.role ? this.role : "",
        type: this.type && this.type.length > 0 ? this.type : [],
        _id: this.index ? this.index : "",
        lastCompany: this.lastCompany ? this.lastCompany : "",
      });
    },
  },
  methods: {
    onEdit() {
      try {
        this.onEditHandler();
      } catch (e) {
        console.error(e);
      }
    },
    onDelete() {
      try {
        this.onDeleteHandler();
      } catch (e) {
        console.error(e);
      }
    },
    isAuthorizedContact(contactObject) {
      return ContactsHelper.isAuthorizedContact(contactObject);
    },
    cardClicked() {
      try {
        this.onClickHandler(this.index);
      } catch (e) {
        console.error(e);
      }
    },
    leftBtnClicked() {
      try {
        this.leftBtnHandler(this.index);
      } catch (e) {
        console.error(e);
      }
    },
    rightBtnClicked() {
      try {
        this.rightBtnHandler(this.index);
      } catch (e) {
        console.error(e);
      }
    },
    getCharAt() {
      if (!this.title) return "";
      let titleArr = this.title.split(" ");
      return titleArr[0] && titleArr[1]
        ? titleArr[0].charAt(0) + titleArr[1].charAt(0)
        : titleArr[0]
        ? (titleArr[0].charAt(0) + titleArr[0].charAt(1)).toUpperCase()
        : titleArr[1]
        ? (titleArr[1].charAt(0) + titleArr[1].charAt(1)).toUpperCase()
        : "";
    },
    getImage(item) {
      return CoreHelper.getImagePath({
        path: item,
        absolutePath: !this.isImg,
      });
    },
  },
};
</script>
<style scoped>
.defaultEstateCard,
.mapCard {
  max-width: 292px !important;
  max-height: 300px !important;
  min-width: 292px !important;
  min-height: 300px !important;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
  margin-left: 11px !important;
  margin-right: 11px !important;
}

@media only screen and (max-width: 1579px) {
  .defaultEstateCard {
    max-width: 292px !important;
    max-height: 450px !important;
    min-width: 292px !important;
  }
}

@media only screen and (max-width: 1379px) {
  .defaultEstateCard {
    margin-left: 11px !important;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 1135px) {
  .defaultEstateCard {
    max-width: 48% !important;
    max-height: 296px !important;
    min-width: 48% !important;
    min-height: 296px !important;
  }
}

@media only screen and (max-width: 642px) {
  .defaultEstateCard {
    max-width: 100% !important;
    max-height: 296px !important;
    min-width: 100% !important;
    min-height: 296px !important;
  }
}

.cardContent {
  margin: 10px 12px 10px 10px;
}

#no-background-hover::before {
  background-color: transparent !important;
}

.topRow {
  height: 44px !important;
  min-height: 44px !important;
  max-height: 44px !important;
}

.midRow {
  height: 220px !important;
  min-height: 220px !important;
  max-height: 220px !important;
}

.botRow {
  height: 55px !important;
  min-height: 55px !important;
  max-height: 55px !important;
}

.titleColor {
  color: #263573;
}

.subTitleColor {
  color: #43425d;
}
</style>
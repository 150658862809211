<template>
  <v-col>
    <v-row>
      <v-col align="left">
        <span class="primaryColor textDecorationBold font15">
          {{ $t("accountInfo") }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        v-if="showAttribute('UserName')"
      >
        <v-text-field
          class="font14"
          v-model="username"
          :label="$t('username') + '*'"
          clearable
          dense
          color="primary"
          hide-details="auto"
          autocomplete="new-password"
          :error-messages="usernameErrors"
        />
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        v-if="showAttribute('status')"
      >
        <v-select
          v-model="status"
          :items="statusList"
          :label="$t('partnerStatus')"
          dense
          clearable
          hide-details="auto"
          return-object
          autocomplete="new-password"
          item-text="label"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        v-if="showAttribute('UserPassword')"
      >
        <v-text-field
          class="font14"
          v-model="password"
          :label="$t('password') + '*'"
          clearable
          dense
          type="password"
          color="primary"
          hide-details="auto"
          autocomplete="new-password"
          :error-messages="passwordErrors"
        />
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        v-if="showAttribute('confirmPassword')"
      >
        <v-text-field
          class="font14"
          v-model="confirmPassword"
          :label="$t('confPass') + '*'"
          clearable
          dense
          type="password"
          color="primary"
          hide-details="auto"
          autocomplete="new-password"
          :error-messages="confirmPasswordErrors"
        />
      </v-col>
      <!-- <v-col
        cols="12"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        v-if="showAttribute('Suffix')"
      >
        <v-text-field
          class="font14"
          v-model="suffix"
          :label="$t('collaboratorCode') + '*'"
          clearable
          dense
          color="primary"
          hide-details="auto"
        >
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="2"
        lg="2"
        xl="2"
        v-if="showAttribute('Suffix')"
      >
        <v-btn
        @click="regSuffix">
          <v-icon color="red"> mdi-refresh </v-icon>
        </v-btn>
      </v-col> -->
    </v-row>
  </v-col>
</template>

<script>

/*
 Name:AccountInfo
 Description: Component to render account info edit fields like password

 API Property:
    attributes: Object with the data
    updateAttribute: Function from the parent to update the data
    validations: Object with the validations from the parent 
    attributeModel: Array with the data model
    kind: String with the info type like "accountInfo"
*/
import i18n from "@/i18n/i18n.js";
export default {
  name: "AccountInfo",
  props: {
    attributes: Object,
    updateAttribute: Function,
    validations: Object,
    attributeModel: Array,
    kind: { type: String, default: "accountInfo" },
  },
  data() {
    return {
      statusList: [
        { label: "Ενεργός", val: "active" },
        { label: "Ανενεργός", val: "non-active" },
        { label: "Ημιενεργός", val: "semi-active" },
      ],
    };
  },
  computed: {
    confirmPasswordErrors() {
      const errors = [];

      if (!this.validations.data.accountInfo.confirmPassword.$dirty)
        return errors;
      if (!this.validations.data.accountInfo.confirmPassword.required)
        errors.push(i18n.t("requiredField"));
      if (!this.validations.data.accountInfo.confirmPassword.sameAsPassword)
        errors.push("Οι κωδικοί πρόσβασης δεν ταιριάζουν");

      return errors;
    },
    usernameErrors() {
      const errors = [];
      if (!this.validations.data.accountInfo.UserName.$dirty) return errors;
      if (!this.validations.data.accountInfo.UserName.required)
        errors.push(i18n.t("requiredField"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.validations.data.accountInfo.UserPassword.$dirty) return errors;
      if (!this.validations.data.accountInfo.UserPassword.required)
        errors.push(i18n.t("requiredField"));
      return errors;
    },
    username: {
      get() {
        return this.attributes.UserName;
      },
      set(value) {
        this.updateAttribute(this.kind, "UserName", value);
      },
    },
    password: {
      get() {
        return this.attributes.UserPassword;
      },
      set(value) {
        this.updateAttribute(this.kind, "UserPassword", value);
      },
    },
    confirmPassword: {
      get() {
        return this.attributes.confirmPassword;
      },
      set(value) {
        this.updateAttribute(this.kind, "confirmPassword", value);
      },
    },
    // suffix: {
    //   get() {
    //     return this.attributes.Suffix;
    //   },
    //   set(value) {
    //     this.updateAttribute(this.kind, "Suffix", value);
    //   },
    // },
    status: {
      get() {
        return this.attributes.status;
      },
      set(value) {
        this.updateAttribute(this.kind, "status", value);
      },
    },
  },
  methods: {
    // genarateNewSuffix() {
    //   this.regSuffix();
    // },
    showAttribute(attribute) {
      return this.attributeModel.includes(attribute);
    },
  },
};
</script>
